import {
  ApiList,
  ApiObject,
  BackofficeCompany,
  Buyer,
  Product,
  Statement,
  DebtStatement,
  Invoice,
  ShinkansenPayment,
  GenericObject,
} from '../../../app/type';

import client from '../client';

export const list = (query?: string, page?: number): Promise<ApiList<BackofficeCompany>> => {
  return client({
    method: 'get',
    url: '/backoffice/companies',
    params: { query, page: page || 1 },
  })
    .then(({ data: body }: { data: ApiList<BackofficeCompany> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const show = (companyId: string): Promise<ApiObject<BackofficeCompany>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}`,
  })
    .then(({ data: body }: { data: ApiObject<BackofficeCompany> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const listBuyers = (
  companyId: string,
  query?: string,
  page?: number
): Promise<ApiList<Buyer>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}/buyers`,
    params: { query, page: page || 1 },
  })
    .then(({ data: body }: { data: ApiList<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const showBuyer = (companyId: string, buyerId: string): Promise<ApiObject<Buyer>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}/buyers/${buyerId}`,
  })
    .then(({ data: body }: { data: ApiObject<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const listProducts = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Product>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}/products`,
    params: { query, page: page || 1, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<Product> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const listStatements = (
  companyId: string,
  onlypays_paid: boolean,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}/statements`,
    params: {
      company_id: companyId,
      onlypays_paid,
      query,
      page: page || 1,
      filter_params: filterParams || {},
    },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const listDebtStatements = (companyId: string): Promise<ApiList<DebtStatement>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}/debt_statements`,
  })
    .then(({ data: body }: { data: ApiList<DebtStatement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const invoices = (companyId: string): Promise<ApiList<Invoice>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}/invoices`,
  })
    .then(({ data: body }: { data: ApiList<Invoice> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const boxesData = (): Promise<ApiObject<{ [key: string]: string }>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/boxes_data`,
  })
    .then(({ data: body }: { data: ApiObject<{ [key: string]: string }> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const payments = (companyId: string): Promise<ApiList<ShinkansenPayment>> => {
  return client({
    method: 'get',
    url: `/backoffice/companies/${companyId}/payments`,
  })
    .then(({ data: body }: { data: ApiList<ShinkansenPayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const retryPayment = (companyId: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/backoffice/companies/${companyId}/retry_payment`,
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};
export const downloadCCTemplate = (): Promise<Blob> => {
  return client({
    method: 'get',
    url: '/backoffice/companies/cc_template',
    responseType: 'blob',
  })
    .then(({ data: body }: { data: Blob }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const sendCCFile = (file: File): Promise<{ message: GenericObject }> => {
  const formData = new FormData();
  formData.append('file', file);
  return client
    .post('/backoffice/companies/create_collection_company_excel', formData)
    .then(({ data: body }: { data: { message: GenericObject } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};
