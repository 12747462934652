import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import styles from '../../features/buyer/Payment.module.scss';
import { KlapCardInfo } from '../../app/type';
import { loadScript } from '../utils';
import { FRONT_URL } from '../../common/api/client';
import klapLogo from '../../assets/images/seller/payment/klap_logo.png';

interface KlapCardFormProps {
  orderId: string;
  companyColor: string;
  submitText: string;
  redirectUrls: string[];
  klapUrl: string;
  payableId?: string;
  payableType?: string;
}

const KlapCardForm = ({
  orderId,
  companyColor,
  submitText,
  redirectUrls,
  payableId,
  payableType,
  klapUrl,
}: KlapCardFormProps): React.ReactElement => {
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);

  const cardSchema = yup.object().shape({
    cardNumberZP: yup
      .string()
      .required('Número de tarjeta requerido')
      .max(19, 'Debe tener un largo máximo de 16 dígitos'),
    name: yup.string().required('Nombre del titular es requerido'),
    cardExpiryDateZP: yup
      .string()
      .required('Fecha de vencimiento requerida')
      .min(5, 'Fecha de vencimiento inválida')
      .test('is-expiration-date-valid', 'Fecha de vencimiento inválida', (value) => {
        if (!value) {
          return false;
        }
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        const [inputMonth, inputYear] = value.split('/').map((v) => parseInt(v, 10));
        return (
          inputYear >= currentYear &&
          inputMonth <= 12 &&
          (inputYear !== currentYear || (inputMonth >= currentMonth && inputMonth <= 12))
        );
      }),
    cardCvvZP: yup.string().required('CVV requerido').min(3, 'CVV inválido'),
  });

  const formik = useFormik<KlapCardInfo>({
    initialValues: {
      cardNumberZP: '',
      name: '',
      cardExpiryDateZP: '',
      cardCvvZP: undefined,
    },
    validationSchema: cardSchema,
    onSubmit: () => {
      setLoadingPayment(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      KLAP.payOrder();
    },
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const id = e.target.id;
    if (id === 'cardNumberZP') {
      const val = e.target.value.replace(/\D/g, '').slice(0, 16);
      const groupedDigits = val.match(/.{1,4}/g);
      const formattedString = groupedDigits ? groupedDigits.join(' ') : '';
      formik.setFieldValue(id, formattedString);
      const cardNumberInput = document.getElementById('cardNumber') as HTMLInputElement;
      if (cardNumberInput) {
        cardNumberInput.value = formattedString; // Usar el valor sin espacios para KLAP
        const event = new Event('input', { bubbles: true });
        cardNumberInput.dispatchEvent(event);
        const changeEvent = new Event('change', { bubbles: true });
        cardNumberInput.dispatchEvent(changeEvent);
      }
    } else if (id === 'name') {
      formik.setFieldValue(id, e.target.value);
    } else if (id === 'cardExpiryDateZP') {
      const val = e.target.value.replace(/\D/g, '').slice(0, 4);
      const year = val.slice(2, 4);
      const formattedString = `${val.slice(0, 2)}${year ? `/${year}` : ''}`;
      formik.setFieldValue(id, formattedString);
      const cardExpiryDateInput = document.getElementById('cardExpiryDate') as HTMLInputElement;
      if (cardExpiryDateInput) {
        cardExpiryDateInput.value = formattedString; // Usar el valor sin espacios para KLAP
        const event = new Event('input', { bubbles: true });
        cardExpiryDateInput.dispatchEvent(event);
        const changeEvent = new Event('change', { bubbles: true });
        cardExpiryDateInput.dispatchEvent(changeEvent);
      }
    } else {
      const val = e.target.value.replace(/\D/g, '').slice(0, 4);
      formik.setFieldValue(id, val);
      const cardCvvInput = document.getElementById('cardCvv') as HTMLInputElement;
      if (cardCvvInput) {
        cardCvvInput.value = val; // Usar el valor sin espacios para KLAP
        const event = new Event('input', { bubbles: true });
        cardCvvInput.dispatchEvent(event);
        const changeEvent = new Event('change', { bubbles: true });
        cardCvvInput.dispatchEvent(changeEvent);
      }
    }
  };

  useEffect(() => {
    loadScript('klap-script', klapUrl, () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      KLAP.init({
        method: 'registro-tarjetas',
        useBinLookup: true,
        debug: true,
        successUrl: `${FRONT_URL}${redirectUrls[0]}${
          payableId ? `&payable_id=${payableId}&payable_type=${payableType}` : ''
        }`,
        errorUrl: `${FRONT_URL}${redirectUrls[1]}`,
      });
    });
  }, []);

  return (
    <div className={styles.formContainer}>
      <Typography mb={2} textAlign="center" variant="h6">
        Ingresa los datos de tu tarjeta
      </Typography>
      <TextField
        fullWidth
        required
        id="cardNumberZP"
        className={styles.inputField}
        placeholder="Número de tarjeta"
        name="cardNumberZP"
        value={formik.values.cardNumberZP}
        onChange={(e) => {
          formik.handleChange(e);
          handleInputChange(e);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.cardNumberZP && Boolean(formik.errors?.cardNumberZP)}
        helperText={formik.touched.cardNumberZP && formik.errors?.cardNumberZP}
      />
      <TextField
        fullWidth
        required
        className={styles.inputField}
        placeholder="Nombre"
        type="text"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={(e) => {
          formik.handleChange(e);
          handleInputChange(e);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors?.name)}
        helperText={formik.touched.name && formik.errors?.name}
      />
      <div className={styles.row}>
        <TextField
          fullWidth
          required
          className={styles.inputField}
          placeholder="MM/YY"
          type="text"
          name="cardExpiryDateZP"
          id="cardExpiryDateZP"
          value={formik.values.cardExpiryDateZP}
          onChange={(e) => {
            formik.handleChange(e);
            handleInputChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.cardExpiryDateZP && Boolean(formik.errors?.cardExpiryDateZP)}
          helperText={formik.touched.cardExpiryDateZP && formik.errors?.cardExpiryDateZP}
        />
        <TextField
          fullWidth
          required
          className={styles.inputField}
          placeholder="CVV"
          type="password"
          name="cardCvvZP"
          id="cardCvvZP"
          value={formik.values.cardCvvZP}
          onChange={(e) => {
            formik.handleChange(e);
            handleInputChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.cardCvvZP && Boolean(formik.errors?.cardCvvZP)}
          helperText={formik.touched.cardCvvZP && formik.errors?.cardCvvZP}
        />
      </div>
      <Button
        variant="contained"
        onClick={() => {
          formik.submitForm();
        }}
        style={{
          backgroundColor: loadingPayment ? '#b9b9b9' : companyColor,
        }}
        className={styles.subscribeCard}
        disabled={loadingPayment}
      >
        {loadingPayment ? <CircularProgress size={20} /> : `${submitText}`}
      </Button>
      <div className={styles.brandRow}>
        <Typography variant="body2">Procesado por</Typography>
        <img src={klapLogo} alt="Logo Klap" />
      </div>
      <form id="checkout-klap" data-klap-order-id={orderId} hidden>
        <input hidden value={1} data-klap-quotas readOnly />
        <input type="checkbox" hidden checked data-klap-generate-token readOnly />
        <input hidden className="form-control" id="cardNumber" type="text" data-klap-card-number />
        <input
          hidden
          className="form-control"
          id="cardExpiryDate"
          type="text"
          data-klap-expiry-date
        />
        <input hidden className="form-control" id="cardCvv" type="text" data-klap-card-cvv />
      </form>
    </div>
  );
};

export default KlapCardForm;
