import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ApiObject,
  Buyer,
  ResponseStatement,
  Product,
  BackofficeCompany,
  DebtStatement,
  Invoice,
  ShinkansenPayment,
} from '../../../app/type';
import { backofficeApi } from '../../../common/api';
import { BackofficeState, setBackofficeCompany } from '../backofficeSlice';
import { useSnackbar } from 'notistack';

import { Grid, Paper, Typography, Button, ButtonGroup, Chip } from '@mui/material';
import Avatar from '../../../common/components/Avatar';
import StatusLabel from '../../../common/components/StatusLabel';
import cStyles from '../../../common/styles/common.module.scss';
import ResourceList from '../../../common/components/ResourceList';
import { formatCurrency, formatNumber } from '../../../common/utils';
import { RECURRENCE_ES } from '../../../common/constants/subscriptions';
import InfoBox from '../../../common/components/InfoBox';
import { PLANS_ES } from '../../../common/constants/plans';
import PopUp from '../../../common/components/PopUp';
import { FRONT_URL } from '../../../common/api/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBucket, faHashtag, faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './Company.module.scss';

const CompanyShow = (): React.ReactElement => {
  const { company } = useSelector(({ backoffice }: { backoffice: BackofficeState }) => backoffice);
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const [selectedTab, selectTab] = useState<string>('paidStatements');
  const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    backofficeApi.companies.show(companyId).then((data: ApiObject<BackofficeCompany>) => {
      dispatch(setBackofficeCompany(data.data));
    });
  }, [companyId]);

  const handleClosePopUp = () => {
    setPopUpOpen(false);
    backofficeApi.companies
      .retryPayment(companyId)
      .then(() => enqueueSnackbar('Se va a procesar el pago', { variant: 'success' }))
      .catch(() => enqueueSnackbar('Ocurrió un error 😵', { variant: 'error' }));
  };

  return (
    <Fragment>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container>
              <Grid item xs={12}>
                <div className={cStyles.paperHeader}>
                  <Avatar
                    className={cStyles.paperHeaderAvatar}
                    text={company?.name}
                    img={company?.image}
                  />
                  <div className={cStyles.paperHeaderContent}>
                    <Typography variant="h5" className="secondary">
                      {company?.name}{' '}
                      <Chip label={company?.status} variant="filled" color="success" />
                    </Typography>
                    <Typography variant="h6" className="secondary">
                      {company?.email}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>

            {/* INFO */}
            {company?.users?.map((user) => (
              <Grid container className={cStyles.infoContainer} key={user.id}>
                <Grid item xs={12} md={4}>
                  <Typography variant="caption">Nombre Usuario:</Typography>
                  <Typography variant="h6">{user.name || '-'}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="caption">Email:</Typography>
                  <Typography variant="h6">{user.email}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="caption">Teléfono:</Typography>
                  <Typography variant="h6">{user.phone || '-'}</Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
      </Grid>

      <div className={styles.infoBoxContainer}>
        <InfoBox
          title="Total recaudado"
          data={formatCurrency(company?.total_gathered)}
          kind="info1"
          style={styles.firstInfoContainer}
          icon={<FontAwesomeIcon icon={faBucket} />}
        />
        <InfoBox
          title="# de pagos pagados"
          data={formatNumber(company?.paid_statements)}
          kind="info1"
          style={styles.secondInfoContainer}
          icon={<FontAwesomeIcon icon={faHashtag} />}
        />
        <InfoBox
          title="Fecha último pago"
          data={company?.last_statement_payment_date}
          kind="info1"
          style={styles.thirdInfoContainer}
          icon={<FontAwesomeIcon icon={faPlus} />}
        />
      </div>

      <Paper className={cStyles.infoPaper} sx={{ mb: 2 }}>
        <Grid container spacing={3} xs={12}>
          <Grid item xs={12}>
            <ButtonGroup variant="text" fullWidth sx={{ fontWeight: 'bold' }}>
              <Button
                onClick={() => selectTab('paidStatements')}
                disabled={selectedTab === 'paidStatements'}
              >
                Cobros pagados ({company?.onlypays_paid_statements})
              </Button>
              <Button
                onClick={() => selectTab('statements')}
                disabled={selectedTab === 'statements'}
              >
                Cobros sin pagar ({company?.onlypays_unpaid_statements})
              </Button>
              <Button onClick={() => selectTab('buyers')} disabled={selectedTab === 'buyers'}>
                Clientes ({company?.number_of_clients})
              </Button>
              <Button onClick={() => selectTab('products')} disabled={selectedTab === 'products'}>
                Servicios ({company?.number_of_products})
              </Button>
              <Button
                onClick={() => selectTab('companyData')}
                disabled={selectedTab === 'companyData'}
              >
                Datos Empresa
              </Button>
              <Button
                onClick={() => selectTab('companyCharges')}
                disabled={selectedTab === 'companyCharges'}
              >
                Cobros a empresa
              </Button>
              <Button onClick={() => selectTab('invoicing')} disabled={selectedTab === 'invoicing'}>
                Facturación
              </Button>
              <Button onClick={() => selectTab('transfers')} disabled={selectedTab === 'transfers'}>
                Transferencias
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>

      {selectedTab === 'paidStatements' && (
        <ResourceList
          title="Cobros Realizados"
          queryFields="Producto"
          getResourceList={(parentId, query, page, filterParams) =>
            backofficeApi.companies.listStatements(parentId, true, query, page, filterParams)
          }
          listHeaders={[
            process.env.NODE_ENV === 'development' ? { key: 'id', label: 'ID del pago' } : null,
            { key: 'amount', label: 'Monto' },
            { key: 'due_date', label: 'Fecha de vencimiento' },
            { key: 'payment_date', label: 'Fecha de pago' },
            { key: 'product', label: 'Producto' },
            { key: 'payment_type', label: 'Método de pago' },
          ]}
          listMobileHeaders={[
            process.env.NODE_ENV === 'development' ? { key: 'id' } : null,
            { key: 'amount' },
            { key: 'due_date' },
          ]}
          listColumns={{
            id: (res: ResponseStatement) => res.id.slice(0, 8),
            product: (res: ResponseStatement) => res.product?.name,
            payment_date: (res: ResponseStatement) => res.payment_date,
            payment_type: (res: ResponseStatement) => res.payment_type,
          }}
          resourceParent={company}
          defaultRoute="backoffice/dashboard"
          excelDownloadMethod={(query) => backofficeApi.paidStatementsExcel(query)}
        />
      )}

      {selectedTab === 'statements' && (
        <ResourceList
          title="Pagos"
          queryFields="Producto"
          getResourceList={(parentId, query, page, filterParams) =>
            backofficeApi.companies.listStatements(parentId, false, query, page, filterParams)
          }
          listHeaders={[
            process.env.NODE_ENV === 'development' ? { key: 'id', label: 'ID del pago' } : null,
            { key: 'amount', label: 'Monto' },
            { key: 'for_plaglabs', label: 'Comisión Plaglabs (sin IVA)' },
            { key: 'product_name', label: 'Producto' },
            { key: 'due_date', label: 'Fecha de vencimiento' },
            { key: 'payment_date', label: 'Fecha de pago' },
            { key: 'payment_type', label: 'Método de pago' },
          ]}
          listColumns={{
            id: (res: ResponseStatement) => res.id.slice(0, 8),
            product_name: (res: ResponseStatement) => res.product?.name,
            subscription_status: (res: ResponseStatement) => (
              <StatusLabel type="subscription" status={res.subscription?.status} />
            ),
            amount: (res: ResponseStatement) => formatCurrency(res.amount),
            for_plaglabs: (res: ResponseStatement) =>
              formatCurrency(parseFloat((res.amount * res.total_commission).toFixed(2))),
            due_date: (res: ResponseStatement) => res.due_date,
            payment_date: (res: ResponseStatement) => res.payment_date,
            recurrence: (res: ResponseStatement) =>
              res.subscription?.recurrence !== undefined
                ? RECURRENCE_ES[res.subscription?.recurrence]
                : null,
            payment_type: (res: ResponseStatement) => res.payment_type,
          }}
          resourceParent={company}
          defaultRoute="backoffice/dashboard"
          excelDownloadMethod={(query) => backofficeApi.paidStatementsExcel(query)}
        />
      )}

      {selectedTab === 'buyers' && (
        <ResourceList
          title="Clientes"
          getResourceList={(parentId, query, page) =>
            backofficeApi.companies.listBuyers(parentId, query, page)
          }
          listHeaders={[
            { key: 'user_name', label: 'Nombre' },
            { key: 'user_email', label: 'Correo' },
          ]}
          listColumns={{
            user_name: (res: Buyer) => res.name,
            user_email: (res: Buyer) => res.email,
          }}
          queryFields="Nombre"
          resourceParent={company}
          defaultRoute="backoffice/companies"
        />
      )}

      {selectedTab === 'products' && (
        <ResourceList
          title="Productos"
          getResourceList={(parentId, query, page, filterParams) =>
            backofficeApi.companies.listProducts(parentId, query, page, filterParams)
          }
          listHeaders={[
            { key: 'prod_name', label: 'Nombre' },
            { key: 'prod_value', label: 'Valor' },
            { key: 'prod_description', label: 'Descripcion' },
          ]}
          listColumns={{
            prod_name: (res: Product) => res.name,
            prod_description: (res: Product) => res.description,
            prod_value: (res: Product) => formatCurrency(res.value),
          }}
          queryFields="Nombre"
          resourceParent={company}
          defaultRoute="backoffice/companies"
        />
      )}
      {selectedTab === 'companyData' && (
        <Paper className={cStyles.infoPaper}>
          {!company?.disabled_client_portal && (
            <Grid mb={2} item xs={12} className={cStyles.paperHeader}>
              <div className={cStyles.paperHeaderContent}>
                <Typography variant="h5">
                  Link portal del cliente:{' '}
                  <span style={{ fontSize: 18 }}>{`${
                    company?.subdomain ? FRONT_URL.replace('app', company?.subdomain) : FRONT_URL
                  }/client/login?company_id=${company?.id}`}</span>
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item xs={12} className={cStyles.paperHeader}>
            <div className={cStyles.paperHeaderContent}>
              <Typography variant="h5">Información Empresa</Typography>
            </div>
          </Grid>
          <Grid container className={cStyles.infoContainer}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Nombre empresa:</Typography>
              <Typography variant="h6">{company?.name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Correo de contacto:</Typography>
              <Typography variant="h6">{company?.email}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Rubro:</Typography>
              <Typography variant="h6">{company?.business_item}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Teléfono:</Typography>
              <Typography variant="h6">{company?.phone}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={cStyles.paperHeader} sx={{ marginTop: '20px' }}>
            <div className={cStyles.paperHeaderContent}>
              <Typography variant="h5">Datos bancarios</Typography>
            </div>
          </Grid>
          <Grid container className={cStyles.infoContainer}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Nombre:</Typography>
              <Typography variant="h6">{company?.bank_informations?.[0].name || '-'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">RUT:</Typography>
              <Typography variant="h6">{company?.bank_informations?.[0].tax_id || '-'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Banco:</Typography>
              <Typography variant="h6">{company?.bank_informations?.[0].bank || '-'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Tipo cuenta:</Typography>
              <Typography variant="h6">
                {company?.bank_informations?.[0].account_type || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Número cuenta:</Typography>
              <Typography variant="h6">
                {company?.bank_informations?.[0].account_number || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Correo:</Typography>
              <Typography variant="h6">{company?.bank_informations?.[0].email || '-'}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={cStyles.paperHeader} sx={{ marginTop: '20px' }}>
            <div className={cStyles.paperHeaderContent}>
              <Typography variant="h5">Plan</Typography>
            </div>
          </Grid>
          <Grid container className={cStyles.infoContainer}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Nombre plan:</Typography>
              <Typography variant="h6">{company?.plan?.name || '-'}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="caption">Descripción</Typography>
              <Typography variant="h6">{PLANS_ES[company?.plan?.name || ''] || '-'}</Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {selectedTab === 'companyCharges' && (
        <ResourceList
          title="Cobros a empresas"
          getResourceList={(companyId) => backofficeApi.companies.listDebtStatements(companyId)}
          listHeaders={[
            { key: 'value', label: 'Valor' },
            { key: 'status', label: 'Estado' },
            { key: 'currency', label: 'Moneda' },
          ]}
          listColumns={{
            value: (res: DebtStatement) => formatCurrency(res.value, res.currency),
            status: (res: DebtStatement) => <StatusLabel status={res.status} type="statement" />,
            currency: (res: DebtStatement) => res.currency,
          }}
          queryFields="None"
          resourceParent={company}
          defaultRoute="backoffice/companies"
        />
      )}
      {selectedTab === 'invoicing' && (
        <ResourceList
          title="Facturaciones"
          getResourceList={(companyId) => backofficeApi.companies.invoices(companyId)}
          listHeaders={[
            { key: 'issue_date', label: 'Fecha de emisión' },
            { key: 'link', label: 'Link a la factura' },
            { key: 'status', label: 'Estado' },
          ]}
          listColumns={{
            issue_date: (res: Invoice) => res.issue_date,
            link: (res: Invoice) => (
              <a href={res.link} target="_blank" rel="noreferrer">
                {res.link}
              </a>
            ),
            status: (res: Invoice) => <StatusLabel status={res.status} type="invoice" />,
          }}
          queryFields="None"
          resourceParent={company}
          defaultRoute="backoffice/companies"
        />
      )}
      {selectedTab === 'transfers' && (
        <>
          <ResourceList
            title="Transferencias"
            getResourceList={backofficeApi.companies.payments}
            queryFields=""
            listHeaders={[
              { key: 'created_at', label: 'Fecha depósito' },
              { key: 'status', label: 'Estado' },
              { key: 'amount', label: 'Monto' },
            ]}
            listColumns={{
              created_at: (response: ShinkansenPayment) => response.created_at,
              status: (response: ShinkansenPayment) => (
                <StatusLabel status={response.status} type="transfer" />
              ),
              amount: (response: ShinkansenPayment) => formatCurrency(response.amount),
            }}
            hideQuery
            resourceParent={company}
          />
          <div className={styles.whiteBackground}>
            <Button variant="contained" onClick={() => setPopUpOpen(true)}>
              Enviar transferencias manualmente
            </Button>
          </div>
        </>
      )}
      <PopUp
        state={{ open: popUpOpen, setOpen: setPopUpOpen }}
        title={<Typography>Confirmación</Typography>}
        content={<Typography>¿Seguro que quieres enviar los pagos de esta empresa?</Typography>}
        extraActions={[
          <Button key={1} onClick={handleClosePopUp} variant="contained">
            Si
          </Button>,
          <Button key={2} onClick={() => setPopUpOpen(false)} variant="outlined">
            No
          </Button>,
        ]}
      />
    </Fragment>
  );
};

export default CompanyShow;
