import React, { useEffect, Fragment, useState } from 'react';
import { useSelector } from '../../../app/hooks';

// Utils
import {
  formatCurrency,
  setDocumentTitle,
  formatDateDashboard,
  formatAmountChart,
} from '../../../common/utils';

// API
import { sellerApi } from '../../../common/api';

// Features
import { SellerState } from '../sellerSlice';

// Components
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import Stepper from '../../../common/components/Stepper';

import {
  faSackDollar,
  faMoneyBillTransfer,
  faMoneyBills,
  faTicket,
  faUserSlash,
  faSackXmark,
} from '@fortawesome/free-solid-svg-icons';

import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/src/component/DefaultTooltipContent';

// Assets
import styles from './Dashboard.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import InfoBox from '../../../common/components/InfoBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { DashboardData } from '../../../app/type';
import { useSnackbar } from 'notistack';
import { TextField } from '@mui/material';
import { DESCRIPTIONS } from '../../../common/constants/dashboard';

const today = new Date();
const beginningMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active) {
    return (
      <div style={{ backgroundColor: variables.simplewhite, padding: '7px 10px' }}>
        {payload?.map((object) => (
          <Fragment key={object.dataKey}>
            <Typography sx={{ fontWeight: '600' }}>{object.payload.label}</Typography>
            <Typography variant="body1">{formatCurrency(object.payload.value)}</Typography>
          </Fragment>
        ))}
      </div>
    );
  }
  return null;
};
const CustomTooltipForNumbers = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active) {
    return (
      <div style={{ backgroundColor: variables.simplewhite, padding: '7px 10px' }}>
        {payload?.map((object) => (
          <Fragment key={object.dataKey}>
            <Typography sx={{ fontWeight: '600' }}>{object.payload.label}</Typography>
            <Typography variant="body1">{object.payload.value}</Typography>
          </Fragment>
        ))}
      </div>
    );
  }
  return null;
};

const DashboardPage = (): React.ReactElement => {
  setDocumentTitle('Dashboard');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [sellerData, setSellerData] = useState<DashboardData>();
  const [rangeDates, setRangeDates] = useState<{ [key: string]: string }>({
    from: formatDateDashboard(beginningMonth),
    to: formatDateDashboard(today),
  });

  const formatYAxis = (value: number): string => {
    return formatAmountChart(value);
  };

  const handleRangeDatesChange = (event: any, context: string) => {
    if (context === 'start') {
      if (rangeDates.to == (undefined || null)) {
        setRangeDates({ ...rangeDates, from: event.target.value });
        return;
      } else {
        if (new Date(event.target.value) > new Date(rangeDates.to)) {
          enqueueSnackbar('La fecha de desde debe ser menor o igual a la fecha hasta.', {
            variant: 'warning',
          });
          return;
        }
        setRangeDates({ ...rangeDates, from: event.target.value });
      }
    } else {
      if (rangeDates.from == (undefined || null)) {
        setRangeDates({ ...rangeDates, to: event.target.value });
        return;
      } else {
        if (new Date(event.target.value) < new Date(rangeDates.from)) {
          enqueueSnackbar('La fecha hasta debe ser mayor o igual a la fecha desde.', {
            variant: 'warning',
          });
          return;
        }
        setRangeDates({ ...rangeDates, to: event.target.value });
      }
    }
  };

  const loadDashboard = () => {
    if (Object.values(rangeDates).some((value) => value === '')) return;
    sellerApi.companies
      .dashboard(company?.id || '', { dates: rangeDates })
      .then((data) => {
        setSellerData(data.data);
      })
      .catch(console.error);
  };

  const boxes = (width?: number) => [
    <InfoBox
      key={1}
      title="Total recaudado"
      data={formatCurrency(sellerData?.summary_stats?.total_gain_period)}
      kind="info2"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faSackDollar} />}
      width={width}
      tooltipMessage={DESCRIPTIONS['gain']}
    />,
    <InfoBox
      key={2}
      title="Transacciones"
      data={sellerData?.summary_stats?.total_transaction_period}
      kind="info2"
      style={styles.firstInfoContainer}
      icon={<FontAwesomeIcon icon={faMoneyBills} />}
      width={width}
      tooltipMessage={DESCRIPTIONS['transactions']}
    />,
    <InfoBox
      key={3}
      title="Ticket promedio"
      data={formatCurrency(sellerData?.summary_stats?.average_ticket_period)}
      style={styles.fourthInfoContainer}
      kind="info2"
      icon={<FontAwesomeIcon icon={faTicket} />}
      width={width}
      tooltipMessage={DESCRIPTIONS['average_ticket']}
    />,
    <InfoBox
      key={4}
      title="Tasa de conversión"
      data={`${sellerData?.summary_stats?.conversion_rate_period || 0}%`}
      kind="info2"
      style={styles.fourthInfoContainer}
      icon={<FontAwesomeIcon icon={faMoneyBillTransfer} />}
      width={width}
      tooltipMessage={DESCRIPTIONS['conversion_rate']}
    />,
    <InfoBox
      key={5}
      title="Total deuda"
      data={formatCurrency(sellerData?.summary_stats?.total_expired_period)}
      kind="info2"
      style={styles.secondInfoContainer}
      icon={<FontAwesomeIcon icon={faSackXmark} />}
      width={width}
      tooltipMessage={DESCRIPTIONS['debt']}
      dataTooltip={[
        ['Suscripciones', sellerData?.summary_stats?.total_expired_sub_period || ''],
        ['Pagos únicos', sellerData?.summary_stats?.total_expired_sp_period || ''],
        ['Planes de pago', sellerData?.summary_stats?.total_expired_pp_period || ''],
      ]}
    />,
    <InfoBox
      key={6}
      title="Total deudores"
      data={sellerData?.summary_stats?.total_debtors_period}
      style={styles.thirdInfoContainer}
      kind="info2"
      icon={<FontAwesomeIcon icon={faUserSlash} />}
      width={width}
      tooltipMessage={DESCRIPTIONS['debtors']}
    />,
  ];

  useEffect(() => {
    loadDashboard();
  }, [rangeDates]);

  return (
    <Fragment>
      <Grid container className={styles.dashboard}>
        <div className={styles.start}>
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            <FontAwesomeIcon icon={faChartColumn} />
            <span className={styles.startTitle}>Resumen</span>
          </Typography>
          <Typography variant="body1">
            Aquí podrás visualizar <b>los resumenes del total</b> que has recaudado
          </Typography>
        </div>
        <div className={styles.filters}>
          <div className={styles.dates}>
            <TextField
              type="date"
              label="Desde"
              InputLabelProps={{ shrink: true }}
              value={rangeDates.from}
              onChange={(event) => handleRangeDatesChange(event, 'start')}
            />
            <TextField
              type="date"
              label="Hasta"
              InputLabelProps={{ shrink: true }}
              value={rangeDates.to}
              onChange={(event) => handleRangeDatesChange(event, 'finish')}
            />
          </div>
        </div>
        <Grid item container className={styles.dashboardContainer}>
          {isMobile ? (
            <Grid item xs={12}>
              <Stepper assets={boxes(12)} />
            </Grid>
          ) : (
            <Grid container flexWrap={'wrap'}>
              {boxes().map((box) => box)}
            </Grid>
          )}
          <Grid item xs={12} className={styles.chartsContainer}>
            <Container className={styles.chart}>
              <Typography className={styles.cardTitle} sx={{ color: '#03507B' }}>
                Recaudado
              </Typography>
              {sellerData?.payments_stats?.length === 0 ? (
                <Typography className={styles.emptyChart} variant="h4">
                  Sin resultados
                </Typography>
              ) : (
                <Fragment>
                  <ResponsiveContainer className={styles.chartDiv}>
                    <BarChart data={sellerData?.payments_stats}>
                      <XAxis dataKey="date" axisLine={false} />
                      <YAxis width={80} tickFormatter={formatYAxis} axisLine={false} />
                      <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
                      <Bar
                        maxBarSize={50}
                        dataKey="value"
                        fill={variables.pending}
                        radius={[10, 10, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Fragment>
              )}
            </Container>
            <Container className={styles.chart}>
              <Typography className={styles.cardTitle} sx={{ color: '#03507B' }}>
                Transacciones
              </Typography>
              {sellerData?.transaction_stats?.length === 0 ? (
                <Typography className={styles.emptyChart} variant="h4">
                  Sin resultados
                </Typography>
              ) : (
                <Fragment>
                  <ResponsiveContainer className={styles.chartDiv}>
                    <BarChart data={sellerData?.transaction_stats}>
                      <XAxis dataKey="date" axisLine={false} />
                      <YAxis width={80} axisLine={false} />
                      <Tooltip
                        content={<CustomTooltipForNumbers />}
                        cursor={{ fill: 'transparent' }}
                      />
                      <Bar
                        maxBarSize={50}
                        dataKey="value"
                        fill={variables.pending}
                        radius={[10, 10, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  <Grid
                    container
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Grid item className={styles.gridRowCenter}>
                      <div className={styles.chipBar}></div>
                      <Typography variant={'body1'}>Transacciones</Typography>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Container>
            <Container className={styles.chart}>
              <Typography className={styles.cardTitle} sx={{ color: '#03507B' }}>
                Deuda
              </Typography>
              {sellerData?.debt_stats?.length === 0 ? (
                <Typography className={styles.emptyChart} variant="h4">
                  Sin resultados
                </Typography>
              ) : (
                <Fragment>
                  <ResponsiveContainer className={styles.chartDiv}>
                    <BarChart data={sellerData?.debt_stats}>
                      <XAxis dataKey="date" axisLine={false} />
                      <YAxis width={80} tickFormatter={formatYAxis} axisLine={false} />
                      <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
                      <Bar
                        maxBarSize={50}
                        dataKey="value"
                        fill={variables.pending}
                        radius={[10, 10, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Fragment>
              )}
            </Container>
            <Container className={styles.chart}>
              <Typography className={styles.cardTitle} sx={{ color: '#03507B' }}>
                Medios de pago
              </Typography>
              {sellerData?.payment_methods_stats?.length === 0 ? (
                <Typography className={styles.emptyChart} variant="h4">
                  Sin resultados
                </Typography>
              ) : (
                <Fragment>
                  <ResponsiveContainer className={styles.chartDiv}>
                    <BarChart data={sellerData?.payment_methods_stats}>
                      <XAxis dataKey="payment_method" axisLine={false} />
                      <YAxis width={80} tickFormatter={formatYAxis} axisLine={false} />
                      <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
                      <Bar
                        maxBarSize={50}
                        dataKey="value"
                        fill={variables.pending}
                        radius={[10, 10, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Fragment>
              )}
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DashboardPage;
