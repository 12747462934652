// React, packages and api
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { sellerApi } from '../../../common/api';
import { useFormik, getIn } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

// Slice and utils
import { SellerState, setSubscription } from '../sellerSlice';
import { useDispatch, useSelector } from '../../../app/hooks';
import {
  setDocumentTitle,
  formatNumber,
  decimalNumberFormatter,
  plainNumberFormatter,
  formatDecimalNumber,
} from '../../../common/utils';
import { RECURRENCE } from '../../../common/constants/subscriptions';
import { NUMBERS, WEEKDAYS } from '../../../common/constants/dates';
import { PERIODS } from '../../../common/constants/products';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slider,
  Chip,
  Box,
  Drawer,
} from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

// Styles and assets
import styles from './Edit.module.scss';
import { Trash as TrashIcon } from 'react-feather';
import { CURRENCIES } from '../../../common/constants/currencies';
import { DESCRIPTIONS, SUBSCRIPTIONS_DESCRIPTIONS } from '../../../common/constants/descriptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

// Componentes
import PopUp from '../../../common/components/PopUp';
import ShippingOptions from '../../../common/components/ShippingOptions';
import InvoicingSetupForm from '../../../common/components/InvoicingSetupForm';

// Types
import {
  ApiObject,
  Buyer,
  DefaultExtraField,
  GenericObject,
  InvoiceConfiguration,
  Subscription,
} from '../../../app/type';
import { InfoButton } from '../../../common/components/InfoButton';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { generateImage } from '../../../common/utils/cropImage';
import { FRONT_URL } from '../../../common/api/client';

const EditSubscription = (): React.ReactElement => {
  const { subscription } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const dispatch = useDispatch();
  const history = useHistory();
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (subscriptionId !== subscription?.id && subscriptionId) {
      sellerApi.subscriptions
        .show(subscriptionId)
        .then((data: ApiObject<Subscription>) => {
          dispatch(setSubscription(data.data));
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        });
    }
  }, [subscriptionId, subscription]);

  const onSuccess = (data: ApiObject<Subscription>) => {
    enqueueSnackbar('Servicio guardado exitosamente', { variant: 'success' });
    dispatch(setSubscription(data.data));
    history.push(`/seller/subscriptions/${data.data.id}`);
  };

  subscriptionId ? setDocumentTitle('Editar suscripción') : setDocumentTitle('Crear suscripción');

  return (
    <>
      <Grid container sx={{ width: 'auto', margin: '2%' }}>
        <Grid item container className={styles.paperHeader}>
          <Grid container item className={styles.paperHeaderContent}>
            <Grid item>
              <Typography variant="h5">
                {subscriptionId ? 'Editar' : 'Crear'} Suscripción
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={styles.paperHeaderContent}>
            <Typography variant="body1">
              Las <b>suscripciones</b> se cobran periódicamente.
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={styles.formContainer}>
          <Paper className={styles.infoPaper}>
            <SubscriptionForm initialData={subscription} onSuccess={onSuccess} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

interface SubscriptionFormProps {
  initialData: Subscription | undefined;
  onSuccess: (data: ApiObject<Subscription>) => void;
}

interface SubscriptionInfo {
  subscription: Partial<Omit<Subscription, 'products'>>;
  products: {
    id: string;
    quantity: number;
    price: number;
    name: string;
    company_id?: string;
    external_id?: string;
  }[];
  product: { id?: string; name?: string; value?: number; currency?: string; external_id?: string };
  invoice_configuration?: InvoiceConfiguration;
}

const InvoiceConfigSchema = yup.object().shape({
  document: yup.string().required().label('Tipo de documento'),
  vat_exempt: yup.boolean().required().label('IVA'),
  emit_at: yup.string().required().label('Emisión documento'),
});

let queryTimeout: ReturnType<typeof setTimeout>;

const SubscriptionForm = (props: SubscriptionFormProps): React.ReactElement => {
  const [options, setOptions] = useState<
    { title: string; groupLetter: string; id: string; price: number; external_id: string }[]
  >([]);
  const { company, subscription } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const dispatch = useDispatch();
  const [loadingProducts, setLoadingProducts] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledFields, setDisabledFields] = useState<{ [key: string]: boolean }>({});
  const { enqueueSnackbar } = useSnackbar();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = React.useState<Area>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showInscriptionsLimit, setShowInscriptionsLimit] = useState<boolean>();
  const [cancelDebt, setCancelDebt] = useState<boolean>(false);
  const [defaultExtraFields, setDefaultExtraFields] = useState<DefaultExtraField[]>();
  const [inputValue, setInputValue] = useState<string>('');
  const [optionsDrawerOpen, setOptionsDrawerOpen] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<string>('');
  const [shippingModalOpen, setShippingModalOpen] = useState<boolean>(false);
  const [autoExtraFields, setAutoExtraFields] = useState<GenericObject>();
  const [invoiceModalOpen, setInvoiceModalOpen] = useState<boolean>(false);

  const initialData = {
    subscription: {
      id: props.initialData?.id,
      name: props.initialData?.name || '',
      status: props.initialData?.status || '',
      periods: props.initialData?.periods || 0,
      buyer: props.initialData?.buyer || ({} as Buyer),
      recurrence: props.initialData?.recurrence || 'month',
      expiration_time: props.initialData?.expiration_time
        ? moment(props.initialData?.expiration_time, 'YYYY-MM-DD HH:mm:ss Z').format(
            'YYYY-MM-DDTHH:mm:ss'
          )
        : '',
      dop: props.initialData?.dop || null,
      has_apportion: props.initialData?.has_apportion || false,
      start_at: props.initialData?.start_at || '',
      custom_recurrence_amount: props.initialData?.custom_recurrence_amount || null,
      cumulative_debt: props.initialData?.cumulative_debt || false,
      currency: props.initialData?.currency || 'CLP',
      extra_fields: props.initialData?.extra_fields || {},
      mandatory_extra_fields: props.initialData?.mandatory_extra_fields || {},
      default_extra_fields: props.initialData?.default_extra_fields || {},
      extra_fields_kind: props.initialData?.extra_fields_kind || {},
      extra_fields_options: props.initialData?.extra_fields_options || {},
      company_id: company?.id || '',
      image: props.initialData?.image || '',
      description: props.initialData?.description || '',
      inscriptions_limit: props.initialData?.inscriptions_limit || null,
      cancel_debt_quantity: props.initialData?.cancel_debt_quantity || null,
      cancel_debt_unit: props.initialData?.cancel_debt_unit || null,
      ask_shipping_address: props.initialData?.ask_shipping_address || false,
      shipping_config: props.initialData?.shipping_config || null,
      auto_extra_fields: props.initialData?.auto_extra_fields || {},
      emit_document:
        props.initialData?.emit_document === false
          ? false
          : company?.invoicing_provider
          ? true
          : null,
      terms_and_conditions_url: props.initialData?.terms_and_conditions_url || null,
    },
    products:
      props.initialData?.subscription_products.length === 1 &&
      props.initialData?.subscription_products[0].product.kind !== 'common'
        ? []
        : props.initialData?.subscription_products.map((subProd) => ({
            id: subProd.product.id,
            quantity: subProd.quantity,
            price: subProd.product.value,
            company_id: company?.id || '',
            name: subProd.product.name || '',
          })) || [],
    product: {
      id: props.initialData?.subscription_products[0].product.id,
      currency: props.initialData?.subscription_products[0].product.currency || 'CLP',
      company_id: company?.id || '',
      value: props.initialData?.subscription_products[0].value,
    },
  };

  const invoiceFormik = useFormik<InvoiceConfiguration>({
    initialValues: {
      id: props.initialData?.invoice_configuration?.document || '',
      document: props.initialData?.invoice_configuration?.document || '',
      vat_exempt: props.initialData?.invoice_configuration?.vat_exempt || false,
      emit_at: props.initialData?.invoice_configuration?.emit_at || '',
      emit_credit_note: props.initialData?.invoice_configuration?.emit_credit_note || false,
      ask_rut: props.initialData?.invoice_configuration?.ask_rut,
    },
    validationSchema: InvoiceConfigSchema,
    onSubmit: () => {
      setInvoiceModalOpen(false);
    },
    enableReinitialize: true,
  });

  const formik = useFormik<SubscriptionInfo>({
    initialValues: initialData,
    onSubmit: (subscriptionInfo: SubscriptionInfo) => {
      if (validateDopAndStartAt()) {
        setLoading(true);
        const request = props.initialData?.id
          ? sellerApi.subscriptions.update
          : sellerApi.subscriptions.create;
        const body =
          formik.values.subscription.emit_document &&
          [true, false].includes(invoiceFormik.values.vat_exempt) &&
          invoiceFormik.values.emit_at
            ? { ...subscriptionInfo, invoice_configuration: invoiceFormik.values }
            : subscriptionInfo;
        request(body)
          .then(props.onSuccess)
          .catch((err: any): void => {
            if (err?.response?.status === 400) {
              if (err.response.data.message === 'no_products_selected') {
                enqueueSnackbar('Debes seleccionar un producto o un precio a la suscripción.', {
                  variant: 'error',
                });
              } else enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
            } else if (err?.response?.status === 403) {
              enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
            }
          })
          .finally(() => setLoading(false));
      }
    },
  });

  const hasProducts = !(
    subscription?.subscription_products.length === 1 &&
    subscription?.subscription_products[0].product.kind !== 'common'
  );

  const getOptions = (_: any, value: string, id: string) => {
    if (company?.id) {
      sellerApi.products
        .list(company.id, value, 1, {
          status: { product: { active: 'activated', inactive: 'deactivated' } },
        })
        .then((data) => {
          const newOptions = data.data.reduce(
            (
              prev: {
                title: string;
                groupLetter: string;
                id: string;
                price: number;
                external_id: string;
              }[],
              curr
            ) =>
              formik.values.products.some((p) => p.id === curr.id)
                ? prev
                : [
                    ...prev,
                    {
                      title: curr.name,
                      groupLetter: Array.from(curr.name)[0].toUpperCase(),
                      id: curr.id,
                      price: curr.value,
                      external_id: curr.external_id,
                    },
                  ],
            []
          );
          setOptions(newOptions);
          setLoadingProducts({ [id]: false });
        });
    }
  };

  const handleQueryChange = (_: any, value: string, id: string) => {
    if (queryTimeout) clearTimeout(queryTimeout);

    setLoadingProducts({ [id]: true });
    queryTimeout = setTimeout(() => {
      getOptions(_, value, id);
    }, 1000);
  };

  const setProduct = (
    _: any,
    value: { title: string; groupLetter: string; id: string; price: number } | null,
    index: number,
    id: string,
    quantity: number
  ) => {
    const temporaryProducts = [...formik.values.products];
    temporaryProducts[index] = {
      id: value?.id || id,
      quantity: quantity,
      price: value?.price || 0,
      name: value?.title || '',
    };
    formik.setFieldValue('products', temporaryProducts);
  };

  const addProduct = () => {
    getOptions('', '', '');
    formik.setFieldValue('products', [
      ...formik.values.products,
      { id: Date.now(), quantity: 1, price: 0 },
    ]);
    if (!disabledFields.value) setDisabledFields({ ...disabledFields, value: true });
  };

  const changeProductQty = (index: number, change: 'minus' | 'plus') => {
    const temporaryProducts = [...formik.values.products];
    if (change === 'minus') {
      temporaryProducts[index] = {
        ...temporaryProducts[index],
        quantity: temporaryProducts[index].quantity - 1,
      };
    } else if (change === 'plus') {
      temporaryProducts[index] = {
        ...temporaryProducts[index],
        quantity: temporaryProducts[index].quantity + 1,
      };
    }
    formik.setFieldValue('products', temporaryProducts);
  };

  const deleteProduct = (index: number) => {
    const temporaryProducts = [...formik.values.products];
    temporaryProducts.splice(index, 1);
    formik.setFieldValue('products', temporaryProducts);
  };

  useEffect(() => {
    if (formik.values.products.length === 0 || !hasProducts)
      setDisabledFields({ ...disabledFields, value: false });
    else setDisabledFields({ ...disabledFields, value: true });
  }, [formik.values.products]);

  const eraseExtraField = (id: string) => {
    formik.setFieldValue(
      'subscription.extra_fields',
      omit(formik.values.subscription.extra_fields, id)
    );
    formik.setFieldValue(
      'subscription.mandatory_extra_fields',
      omit(formik.values.subscription.mandatory_extra_fields, id)
    );
  };

  const addExtraField = () => {
    const date = Date.now();
    if (formik.values.subscription.extra_fields) {
      formik.setFieldValue('subscription.extra_fields', {
        ...formik.values.subscription.extra_fields,
        [date]: '',
      });
      formik.setFieldValue('subscription.mandatory_extra_fields', {
        ...formik.values.subscription.mandatory_extra_fields,
        [date]: false,
      });
      formik.setFieldValue('subscription.extra_fields_kind', {
        ...formik.values.subscription.extra_fields_kind,
        [date]: 'string',
      });
    }
  };

  const changeMandatoryField = (checked: boolean, id: string) => {
    formik.setFieldValue('subscription.mandatory_extra_fields', {
      ...formik.values.subscription.mandatory_extra_fields,
      [id]: checked,
    });
  };

  const changeFieldKind = (kind: string, id: string) => {
    formik.setFieldValue('subscription.extra_fields_kind', {
      ...formik.values.subscription.extra_fields_kind,
      [id]: kind,
    });
  };

  const changeDefaultField = (checked: boolean, id: string) => {
    if (checked) {
      formik.setFieldValue('subscription.default_extra_fields', {
        ...formik.values.subscription.default_extra_fields,
        [id]: true,
      });
    } else {
      formik.setFieldValue(
        'subscription.default_extra_fields',
        omit(formik.values.subscription.default_extra_fields, id)
      );
    }
  };

  const changeAutoExtraField = (checked: boolean, name: string) => {
    formik.setFieldValue('subscription.auto_extra_fields', {
      ...formik.values.subscription.auto_extra_fields,
      [name]: checked,
    });
  };
  const changeRecurrence = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.handleChange(event);
    if (!['month', 'week'].includes(event.target.value)) {
      formik.setFieldValue('subscription.periods', null);
    }
    if (!['month', 'week'].includes(event.target.value)) {
      formik.setFieldValue('subscription.dop', null);
    }
  };

  const changeStartAtSelection = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDisabledFields({
      ...disabledFields,
      start_at: event.target.value === 'true' ? true : false,
    });
    formik.setFieldValue('subscription.start_at', '');
  };

  const changeDopSelection = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDisabledFields({ ...disabledFields, dop: event.target.value === 'true' ? true : false });
    formik.setFieldValue('subscription.dop', null);
    formik.setFieldValue('subscription.has_apportion', false);
  };

  const changeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.handleChange(event);
    formik.setFieldValue('product.name', event.target.value);
  };

  const changeInscriptionsLimit = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value === 'true') {
      formik.setFieldValue('subscription.inscriptions_limit', 1);
      setShowInscriptionsLimit(true);
    } else {
      setShowInscriptionsLimit(false);
      formik.setFieldValue('subscription.inscriptions_limit', null);
    }
  };

  const changeCancelDebt = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.setFieldValue(
      'subscription.cancel_debt_quantity',
      event.target.value === 'true' ? 1 : null
    );
    formik.setFieldValue(
      'subscription.cancel_debt_unit',
      event.target.value === 'true' ? 'days' : null
    );
    setCancelDebt(!cancelDebt);
  };

  const changeCancelQuantity = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (parseInt(event.target.value) <= 0) {
      formik.setFieldValue('subscription.cancel_debt_quantity', 1);
    } else {
      formik.handleChange(event);
    }
  };

  const changeAskShipping = (checked: boolean) => {
    formik.setFieldValue('subscription.ask_shipping_address', checked);
  };

  const [urlImage, setUrlImage] = useState<string>(formik.values.subscription.image || '');

  const onUploadImage = (event: any) => {
    setDialogOpen(!dialogOpen);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };
                setUrlImage(URL.createObjectURL(blob));
                newImg.src = url;
                formik.setFieldValue('subscription.image_file', blob);
                enqueueSnackbar('Imagen procesada con éxito', { variant: 'success' });
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addExtraFieldOptions();
    }
  };

  const addExtraFieldOptions = () => {
    if (inputValue.trim()) {
      const currentTags = formik.values.subscription.extra_fields_options?.[selectedField] || '';
      const newTags = currentTags ? `${currentTags},${inputValue.trim()}` : inputValue.trim();
      formik.setFieldValue(`subscription.extra_fields_options.${selectedField}`, newTags);
      setInputValue('');
    }
  };

  const handleDelete = (tagToDelete: string, id: string) => {
    const currentTags = formik.values.subscription.extra_fields_options?.[id] || '';
    const updatedTags = currentTags
      .split(',')
      .filter((tag) => tag !== tagToDelete)
      .join(',');

    formik.setFieldValue(`subscription.extra_fields_options.${id}`, updatedTags);
  };

  const openOptionsDrawer = (id: string) => {
    setOptionsDrawerOpen(true);
    setSelectedField(id);
  };

  const closeOptionsDrawer = () => {
    setOptionsDrawerOpen(false);
    setSelectedField('');
  };

  const openDispatchConfig = () => {
    window.open(`${FRONT_URL}/seller/company/dispatch_setup`);
  };

  const saveShippingConfig = (regions: string[], communes: { [key: string]: string[] }) => {
    formik.setFieldValue('subscription.shipping_config', {
      regions: regions,
      communes_by_region: communes,
    });
    setShippingModalOpen(false);
  };

  const cancelShippingInfo = () => {
    formik.setFieldValue(
      'subscription.shipping_config',
      props.initialData?.shipping_config || null
    );
    setShippingModalOpen(false);
  };

  const openInvoiceConfig = () => {
    window.open(`${FRONT_URL}/seller/company/invoicing_setup`);
  };

  const deleteSubscriptionInvoiceConfig = () => {
    setLoading(true);
    sellerApi.subscriptions
      .deleteInvoiceConfiguration(
        subscription?.id || '',
        subscription?.invoice_configuration?.id || ''
      )
      .then((data) => {
        dispatch(setSubscription(data.data));
        setInvoiceModalOpen(false);
        enqueueSnackbar('Información actualizada exitosamente', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (subscription) {
      setDisabledFields({
        dop: subscription.dop ? false : true,
        start_at: subscription.start_at ? false : true,
        value: hasProducts ? true : false,
      });
      setShowInscriptionsLimit(subscription?.inscriptions_limit ? true : false);
    } else setDisabledFields({ dop: true, start_at: true });
  }, []);

  useEffect(() => {
    sellerApi.defaultExtraField.list(company?.id || '').then((data) => {
      setDefaultExtraFields(data.data['default_extra_fields']);
      setAutoExtraFields(data.data['auto_extra_fields']);
      formik.setFieldValue('subscription.auto_extra_fields', data.data['auto_extra_fields']);
    });
  }, []);

  useMemo(() => {
    setCancelDebt(Boolean(props.initialData?.cancel_debt_quantity));
  }, [props.initialData?.cancel_debt_quantity]);

  const validateDopAndStartAt = () => {
    if (
      formik.values.subscription.dop &&
      formik.values.subscription.start_at &&
      formik.values.subscription.start_at.length > 1
    ) {
      if (
        parseInt(formik.values.subscription.start_at.split('-')[2]) !==
        formik.values.subscription.dop
      ) {
        formik.setFieldError(
          'subscription.dop',
          'Valor inválido. Si fijas una fecha para el Primer cobro, debe ser el mismo día.'
        );
        enqueueSnackbar(
          'Si fijas un Día del pago y la fecha del Primer cobro, deben ser el mismo día.',
          { variant: 'error' }
        );
        return false;
      } else {
        formik.setFieldError('subscription.dop', undefined);
        return true;
      }
    }
    return true;
  };

  return (
    <form
      onKeyPress={(event: any) => event.which == 13 && event.preventDefault()}
      onSubmit={formik.handleSubmit}
    >
      <Paper className={styles.backgroundPaper}>
        <div className={styles.subscriptionContainer}>
          <div className={styles.description}>
            <div className={styles.informationContainer}>
              <div className={styles.containerHeader}>
                <Typography variant="h6">
                  Nombre<span className={styles.required}>*</span>
                </Typography>
              </div>
              <TextField
                fullWidth
                required
                id="subscription.name"
                type="text"
                autoComplete="subscription.name"
                variant="outlined"
                value={formik.values.subscription.name}
                onChange={(event) => changeName(event)}
                error={
                  getIn(formik.touched, 'subscription.name') &&
                  Boolean(getIn(formik.errors, 'subscription.name'))
                }
                helperText={
                  getIn(formik.touched, 'subscription.name') &&
                  getIn(formik.errors, 'subscription.name')
                }
              />
            </div>
            <div className={styles.informationContainer}>
              <div className={styles.containerHeader}>
                <Typography variant="h6">Descripción</Typography>
              </div>
              <TextField
                fullWidth
                id="subscription.description"
                type="text"
                autoComplete="subscription.description"
                variant="outlined"
                value={formik.values.subscription.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={styles.image}>
            <Grid item xs={6} className={styles.gridProductImage}>
              <Typography className={styles.photoTitle}>Imagen de la Suscripción</Typography>
              <Paper className={styles.paperProductImage}>
                <Container
                  id="imageContainer"
                  className={styles.containerProductImage}
                  sx={{ backgroundImage: `url(${urlImage})` }}
                >
                  <Button className={styles.buttonProductImage} component="label">
                    <CameraAltOutlinedIcon sx={{ color: '#FFFFFF' }} />
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      id="image"
                      onChange={onUploadImage}
                    />
                  </Button>
                </Container>
              </Paper>
              <Typography className={styles.photoSubtitle}>
                Te aconsejamos agregarlo para que tus clientes lo visualicen.
              </Typography>
            </Grid>
          </div>
        </div>
      </Paper>

      {hasProducts ? (
        <Paper className={styles.backgroundPaper}>
          <div className={styles.informationContainer}>
            <div className={styles.containerHeader}>
              <Typography variant="h6">Selección de Productos/Servicios</Typography>
              <Typography variant="subtitle1">
                Estos productos/servicios serán parte de tu suscripción
              </Typography>
            </div>
            {formik.values.products.length === 0 ? (
              <Typography>
                Si no agregas un producto, puedes setear el precio de la suscripción más abajo
              </Typography>
            ) : null}
            {formik.values.products?.map((product, index) => (
              <div key={index} className={styles.productContainer}>
                <Autocomplete
                  options={options.sort((a, b) => -b.groupLetter.localeCompare(a.groupLetter))}
                  groupBy={(option) => option.groupLetter}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter(
                      (option) =>
                        option.title?.toLowerCase()?.includes(inputValue) ||
                        option.external_id?.toLowerCase()?.includes(inputValue)
                    );
                  }}
                  getOptionLabel={(option) => option.title}
                  onInputChange={(event, value) => handleQueryChange(event, value, product.id)}
                  onChange={(event, value) =>
                    setProduct(event, value, index, product.id, product.quantity)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          ...(product.company_id ? { value: product.name } : {}),
                        },
                      }}
                      value={product.name}
                      label="Buscar producto"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loadingProducts[product.id] ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                  disabled={initialData.products.some((p) => p.id === product.id)}
                />
                <div className={styles.productQuantity}>
                  <Button
                    variant="outlined"
                    onClick={() => changeProductQty(index, 'minus')}
                    disabled={product.quantity === 1}
                  >
                    <Typography>-</Typography>
                  </Button>
                  <Typography>{product.quantity}</Typography>
                  <Button variant="outlined" onClick={() => changeProductQty(index, 'plus')}>
                    <Typography>+</Typography>
                  </Button>
                </div>
                <IconButton
                  size="medium"
                  onClick={() => deleteProduct(index)}
                  className={styles.icon}
                >
                  {<TrashIcon />}
                </IconButton>
              </div>
            ))}
            <div className={styles.addProductButton}>
              <Typography>
                <a onClick={addProduct}>Agregar producto</a>
              </Typography>
            </div>
          </div>
        </Paper>
      ) : null}

      <Paper className={styles.backgroundPaper}>
        <div className={styles.informationContainer}>
          <div className={styles.containerHeader}>
            <Typography variant="h6">Reglas de la suscripción</Typography>
            <Typography variant="subtitle1">Configura y personaliza tu suscripción</Typography>
          </div>
          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>
                Precio de la suscripción<span className={styles.required}>*</span>
              </Typography>
              <InfoButton text="price" context="subscription" />
            </Grid>
            <div className={styles.priceContainer}>
              <TextField
                className={styles.currency}
                id="product.currency"
                type="text"
                select
                disabled={disabledFields.value}
                autoComplete="product.currency"
                variant="outlined"
                name="product.currency"
                value={formik.values.product.currency || 'CLP'}
                onChange={formik.handleChange}
                error={
                  getIn(formik.touched, 'product.currency') &&
                  Boolean(getIn(formik.errors, 'product.currency'))
                }
                helperText={
                  (getIn(formik.touched, 'product.currency') &&
                    getIn(formik.errors, 'product.currency')) ||
                  'Moneda'
                }
              >
                {CURRENCIES.map((curr: string) => (
                  <MenuItem key={curr} value={curr}>
                    {curr}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={styles.amount}
                id="product.value"
                required={!disabledFields.value}
                autoComplete="product.value"
                variant="outlined"
                name="product.value"
                disabled={disabledFields.value}
                value={
                  disabledFields.value
                    ? formatNumber(
                        formik.values.products
                          .reduce(
                            (prev: number, curr) => prev + (curr.price || 0) * curr.quantity,
                            0
                          )
                          .toString() || '0'
                      )
                    : formik.values.product.currency === 'UF'
                    ? formatDecimalNumber(formik.values.product.value)
                    : formatNumber(formik.values.product.value)
                }
                onChange={(event) => {
                  const amount =
                    formik.values.product.currency === 'UF'
                      ? decimalNumberFormatter(event.target.value)
                      : plainNumberFormatter(event.target.value);
                  formik.setFieldValue('product.value', amount);
                }}
                placeholder={
                  disabledFields.value
                    ? formatNumber(
                        formik.values.products
                          .reduce(
                            (prev: number, curr) => prev + (curr.price || 0) * curr.quantity,
                            0
                          )
                          .toString() || '0'
                      )
                    : undefined
                }
                error={
                  getIn(formik.touched, 'product.value') &&
                  Boolean(getIn(formik.errors, 'product.value'))
                }
                helperText={
                  (getIn(formik.touched, 'product.value') &&
                    getIn(formik.errors, 'product.value')) ||
                  'Monto'
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formik.values.product.currency === 'CLP' ? '$' : 'UF'}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className={styles.recurrenceContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>
                Recurrencia en el cobro<span className={styles.required}>*</span>
              </Typography>
              <InfoButton text="recurrence" context="subscription" />
            </Grid>
            <div className={styles.recurrenceInput}>
              <TextField
                className={styles.amount}
                id="subscription.recurrence"
                type="text"
                autoComplete="subscription.recurrence"
                fullWidth
                select
                variant="outlined"
                name="subscription.recurrence"
                value={formik.values.subscription.recurrence}
                onChange={changeRecurrence}
                error={
                  getIn(formik.touched, 'subscription.recurrence') &&
                  Boolean(getIn(formik.errors, 'subscription.recurrence'))
                }
                helperText={
                  getIn(formik.touched, 'subscription.recurrence') &&
                  getIn(formik.errors, 'subscription.recurrence')
                }
              >
                {RECURRENCE.slice(1).map((rec: string[]) => (
                  <MenuItem key={rec[0]} value={rec[0]}>
                    {rec[1]}
                  </MenuItem>
                ))}
              </TextField>
              {formik.values.subscription.recurrence === 'custom' && (
                <Grid item xs={12} md={3} className={styles.recurrenceGrid}>
                  <Typography>Cada</Typography>
                  <TextField
                    required
                    id="subscription.custom_recurrence_amount"
                    name="subscription.custom_recurrence_amount"
                    type="number"
                    variant="standard"
                    defaultValue={0}
                    value={formik.values.subscription.custom_recurrence_amount}
                    onChange={formik.handleChange}
                    error={
                      getIn(formik.touched, 'subscription.custom_recurrence_amount') &&
                      Boolean(getIn(formik.errors, 'subscription.custom_recurrence_amount'))
                    }
                    helperText={
                      getIn(formik.touched, 'subscription.custom_recurrence_amount') &&
                      getIn(formik.errors, 'subscription.custom_recurrence_amount')
                    }
                    inputProps={{ step: 1, min: 1 }}
                  />
                  <Typography>días</Typography>
                </Grid>
              )}
            </div>
          </div>

          {['month', 'week'].includes(formik.values.subscription.recurrence || '') ? (
            <div className={styles.fieldContainer}>
              <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                <Typography className={styles.fieldHeader}>Duración de la suscripción</Typography>
                <InfoButton text="duration" context="subscription" />
              </Grid>
              <TextField
                className={styles.amount}
                id="subscription.periods"
                type="text"
                autoComplete="subscription.periods"
                fullWidth
                select
                variant="outlined"
                name="subscription.periods"
                placeholder="Duración indefinida"
                value={formik.values.subscription.periods}
                onChange={formik.handleChange}
                error={
                  getIn(formik.touched, 'subscription.periods') &&
                  Boolean(getIn(formik.errors, 'subscription.periods'))
                }
                helperText={
                  getIn(formik.touched, 'subscription.periods') &&
                  getIn(formik.errors, 'subscription.periods')
                }
              >
                <MenuItem key={0} value={0}>
                  Indefinida
                </MenuItem>
                {NUMBERS.slice(1, 13).map((rec: number) => (
                  <MenuItem key={rec} value={rec}>
                    {rec + ' ' + PERIODS[formik.values.subscription.recurrence || 'month']}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : null}

          {['month', 'week'].includes(formik.values.subscription.recurrence || '') ? (
            <div className={styles.fieldContainer}>
              <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                <Typography className={styles.fieldHeader}>Día del pago</Typography>
                <InfoButton text="dop" context="subscription" />
              </Grid>
              <div className={styles.multiInputContainer}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  defaultValue={formik.values.subscription.dop ? 'false' : 'true'}
                  onChange={changeDopSelection}
                  className={styles.option}
                >
                  <MenuItem value={'true'}>Distinto por cliente</MenuItem>
                  <MenuItem value={'false'}>Un día fijo para todos</MenuItem>
                </TextField>
                {!disabledFields.dop && (
                  <TextField
                    className={`${styles.amount} ${styles.input}`}
                    id="subscription.dop"
                    type="text"
                    select
                    autoComplete="subscription.dop"
                    fullWidth
                    variant="outlined"
                    name="subscription.dop"
                    value={formik.values.subscription.dop}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors?.subscription?.dop)}
                    helperText={formik.errors?.subscription?.dop}
                  >
                    {formik.values.subscription.recurrence === 'week'
                      ? WEEKDAYS.map((rec: (number | string)[]) => (
                          <MenuItem key={rec[0]} value={rec[0]}>
                            {rec[1]}
                          </MenuItem>
                        ))
                      : NUMBERS.map((rec: number) => (
                          <MenuItem key={rec} value={rec}>
                            {rec}
                          </MenuItem>
                        ))}
                  </TextField>
                )}
              </div>
            </div>
          ) : null}

          {disabledFields.dop ? null : (
            <div className={styles.fieldContainer}>
              <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                <Typography className={styles.fieldHeader}>Prorrateo</Typography>
                <InfoButton text="apportion" context="subscription" />
              </Grid>
              <TextField
                className={styles.amount}
                id="subscription.has_apportion"
                type="date"
                autoComplete="subscription.has_apportion"
                defaultValue="false"
                fullWidth
                select
                variant="outlined"
                name="subscription.has_apportion"
                value={formik.values.subscription.has_apportion}
                onChange={(event) =>
                  formik.setFieldValue(
                    'subscription.has_apportion',
                    event.target.value === 'true' ? true : false
                  )
                }
                error={
                  getIn(formik.touched, 'subscription.has_apportion') &&
                  Boolean(getIn(formik.errors, 'subscription.has_apportion'))
                }
                helperText={
                  getIn(formik.touched, 'subscription.has_apportion') &&
                  getIn(formik.errors, 'subscription.has_apportion')
                }
              >
                <MenuItem value="true">Sí</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
            </div>
          )}

          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>
                Establecer fecha de expiración de link de pago
              </Typography>
              <InfoButton text="expiration" context="subscription" />
            </Grid>
            <TextField
              className={styles.amount}
              id="subscription.expiration_time"
              type="datetime-local"
              autoComplete="subscription.expiration_time"
              fullWidth
              variant="outlined"
              name="subscription.expiration_time"
              value={formik.values.subscription.expiration_time}
              onChange={formik.handleChange}
              error={
                getIn(formik.touched, 'subscription.expiration_time') &&
                Boolean(getIn(formik.errors, 'subscription.expiration_time'))
              }
              helperText={
                getIn(formik.touched, 'subscription.expiration_time') &&
                getIn(formik.errors, 'subscription.expiration_time')
              }
            />
          </div>

          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>Primer cobro</Typography>
              <InfoButton text="startAt" context="subscription" />
            </Grid>
            <div className={styles.multiInputContainer}>
              <TextField
                fullWidth
                select
                variant="outlined"
                defaultValue={formik.values.subscription.start_at ? 'false' : 'true'}
                onChange={changeStartAtSelection}
                className={styles.option}
              >
                <MenuItem value={'true'}>Cuando el cliente se suscriba</MenuItem>
                <MenuItem value={'false'}>En una fecha determinada</MenuItem>
              </TextField>
              {!disabledFields.start_at && (
                <TextField
                  className={`${styles.amount} ${styles.input}`}
                  id="subscription.start_at"
                  type="date"
                  autoComplete="subscription.start_at"
                  fullWidth
                  variant="outlined"
                  name="subscription.start_at"
                  value={formik.values.subscription.start_at}
                  onChange={formik.handleChange}
                  error={
                    getIn(formik.touched, 'subscription.start_at') &&
                    Boolean(getIn(formik.errors, 'subscription.start_at'))
                  }
                  helperText={
                    getIn(formik.touched, 'subscription.start_at') &&
                    getIn(formik.errors, 'subscription.start_at')
                  }
                />
              )}
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>Deuda acumulable</Typography>
              <InfoButton text="cumulativeDebt" context="subscription" />
            </Grid>
            <TextField
              className={styles.amount}
              id="subscription.cumulative_debt"
              type="date"
              autoComplete="subscription.cumulative_debt"
              defaultValue="false"
              fullWidth
              select
              variant="outlined"
              name="subscription.cumulative_debt"
              placeholder="Hola"
              value={formik.values.subscription.cumulative_debt}
              onChange={(event) =>
                formik.setFieldValue(
                  'subscription.cumulative_debt',
                  event.target.value === 'true' ? true : false
                )
              }
              error={
                getIn(formik.touched, 'subscription.cumulative_debt') &&
                Boolean(getIn(formik.errors, 'subscription.cumulative_debt'))
              }
              helperText={
                getIn(formik.touched, 'subscription.cumulative_debt') &&
                getIn(formik.errors, 'subscription.cumulative_debt')
              }
            >
              <MenuItem value="true">Sí</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </div>

          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>Límite de inscripciones</Typography>
              <InfoButton text="inscriptionsLimit" context="subscription" />
            </Grid>
            <div className={styles.multiInputContainer}>
              <TextField
                fullWidth
                select
                variant="outlined"
                defaultValue={formik.values.subscription.inscriptions_limit ? 'true' : 'false'}
                onChange={changeInscriptionsLimit}
                className={styles.option}
              >
                <MenuItem value={'true'}>Sí</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </TextField>
              {showInscriptionsLimit && (
                <TextField
                  className={`${styles.amount} ${styles.input}`}
                  id="subscription.inscriptions_limit"
                  type="number"
                  inputProps={{ min: 1 }}
                  autoComplete="subscription.inscriptions_limit"
                  fullWidth
                  variant="outlined"
                  name="subscription.inscriptions_limit"
                  value={formik.values.subscription.inscriptions_limit}
                  onChange={formik.handleChange}
                  error={
                    getIn(formik.touched, 'subscription.inscriptions_limit') &&
                    Boolean(getIn(formik.errors, 'subscription.inscriptions_limit'))
                  }
                  helperText={
                    getIn(formik.touched, 'subscription.inscriptions_limit') &&
                    getIn(formik.errors, 'subscription.inscriptions_limit')
                  }
                ></TextField>
              )}
            </div>
          </div>

          {!formik.values.subscription.cumulative_debt && (
            <div className={styles.fieldContainer}>
              <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                <Typography className={styles.fieldHeader}>
                  Eliminar inscripciones abandonadas
                </Typography>
                <InfoButton text="cancelDebt" context="subscription" />
              </Grid>
              <div className={styles.multiInputContainer}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  defaultValue={formik.values.subscription.cancel_debt_quantity ? 'true' : 'false'}
                  onChange={changeCancelDebt}
                  className={styles.option}
                >
                  <MenuItem value={'true'}>Sí</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </TextField>
                {cancelDebt && (
                  <>
                    <TextField
                      className={`${styles.amount} ${styles.input}`}
                      id="subscription.cancel_debt_quantity"
                      type="number"
                      autoComplete="subscription.cancel_debt_quantity"
                      fullWidth
                      variant="outlined"
                      name="subscription.cancel_debt_quantity"
                      value={formik.values.subscription.cancel_debt_quantity}
                      onChange={changeCancelQuantity}
                      error={
                        getIn(formik.touched, 'subscription.cancel_debt_quantity') &&
                        Boolean(getIn(formik.errors, 'subscription.cancel_debt_quantity'))
                      }
                      helperText={
                        getIn(formik.touched, 'subscription.cancel_debt_quantity') &&
                        getIn(formik.errors, 'subscription.cancel_debt_quantity')
                      }
                    />
                    <TextField
                      className={`${styles.amount} ${styles.input}`}
                      id="subscription.cancel_debt_unit"
                      type="text"
                      autoComplete="subscription.cancel_debt_unit"
                      select
                      fullWidth
                      variant="outlined"
                      name="subscription.cancel_debt_unit"
                      value={formik.values.subscription.cancel_debt_unit}
                      onChange={formik.handleChange}
                      error={
                        getIn(formik.touched, 'subscription.cancel_debt_unit') &&
                        Boolean(getIn(formik.errors, 'subscription.cancel_debt_unit'))
                      }
                      helperText={
                        getIn(formik.touched, 'subscription.cancel_debt_unit') &&
                        getIn(formik.errors, 'subscription.cancel_debt_unit')
                      }
                    >
                      <MenuItem value="days">
                        {(formik.values.subscription.cancel_debt_quantity || 1) > 1
                          ? 'días'
                          : 'día'}
                      </MenuItem>
                      <MenuItem value="weeks">
                        {(formik.values.subscription.cancel_debt_quantity || 1) > 1
                          ? 'semanas'
                          : 'semana'}
                      </MenuItem>
                      <MenuItem value="months">
                        {(formik.values.subscription.cancel_debt_quantity || 1) > 1
                          ? 'meses'
                          : 'mes'}
                      </MenuItem>
                      <MenuItem value="years">
                        {(formik.values.subscription.cancel_debt_quantity || 1) > 1
                          ? 'años'
                          : 'año'}
                      </MenuItem>
                    </TextField>
                  </>
                )}
              </div>
            </div>
          )}
          <div className={styles.fieldContainer}>
            <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
              <Typography className={styles.fieldHeader}>Términos y condiciones</Typography>
              <InfoButton text="termsAndConditions" context="subscription" />
            </Grid>
            <TextField
              className={styles.amount}
              id="subscription.terms_and_conditions_url"
              type="text"
              autoComplete="subscription.terms_and_conditions_url"
              fullWidth
              variant="outlined"
              name="subscription.terms_and_conditions_url"
              value={formik.values.subscription.terms_and_conditions_url}
              onChange={formik.handleChange}
            />
          </div>
          {company?.invoicing_provider && (
            <div className={styles.fieldContainer}>
              <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                <Typography className={styles.fieldHeader}>Emitir boleta/factura</Typography>
                <InfoButton text="emitDocument" context="subscription" />
              </Grid>
              <div className={styles.a}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  defaultValue={formik.values.subscription.emit_document ? 'true' : 'false'}
                  onChange={(event) =>
                    formik.setFieldValue(
                      'subscription.emit_document',
                      event.target.value === 'true' ? true : false
                    )
                  }
                  className={styles.option}
                >
                  <MenuItem value={'true'}>Sí</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </TextField>
                {formik.values.subscription.emit_document && (
                  <Fragment>
                    <Typography mt={0.5} fontSize={14}>
                      *Se pedirán automáticamente los datos necesarios para emitir el documento
                      cuando el cliente se inscriba al servicio.
                    </Typography>
                    <div className={styles.shippingExplain}>
                      {subscription?.invoice_configuration ? (
                        <Typography>
                          <a
                            className={styles.addExtraFieldButton}
                            onClick={() => setInvoiceModalOpen(true)}
                          >
                            Editar configuración de emisión de boleta/factura
                          </a>
                        </Typography>
                      ) : (
                        <Typography>
                          Se ocupará por defecto la{' '}
                          <a className={styles.addExtraFieldButton} onClick={openInvoiceConfig}>
                            configuración empresa
                          </a>
                          . O puedes definir otra configuración para esta suscripción{' '}
                          <a
                            className={styles.addExtraFieldButton}
                            onClick={() => setInvoiceModalOpen(true)}
                          >
                            aquí.
                          </a>
                        </Typography>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </Paper>

      <Paper className={styles.backgroundPaper}>
        <div className={styles.informationContainer}>
          <div className={styles.containerHeader}>
            <Typography variant="h6">Datos solicitados al cliente</Typography>
            <Typography variant="subtitle1">{DESCRIPTIONS['additionalInfo']}</Typography>
          </div>
          <div className={styles.extraFieldsContainer}>
            <div className={styles.defaultExtraField}>
              <Typography variant="subtitle1">Nombre completo</Typography>
              <Typography variant="body1">Campo obligatorio. No se puede modificar.</Typography>
            </div>
            <div className={styles.defaultExtraField}>
              <Typography variant="subtitle1">Correo</Typography>
              <Typography variant="body1">Campo obligatorio. No se puede modificar.</Typography>
            </div>
            <Divider sx={{ marginBottom: '20px' }} />
            <div className={styles.defaultEfsContainer}>
              <Typography variant="h6">Campos extra predefinidos</Typography>
              <Typography variant="body1">
                Estos campos se pedirán siempre en caso de que los selecciones.
              </Typography>
              <div className={styles.defaultFieldRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="ask_shipping_address"
                      onChange={(e, checked) => changeAskShipping(checked)}
                      checked={formik.values.subscription.ask_shipping_address}
                    />
                  }
                  label="Dirección de despacho"
                  labelPlacement="end"
                />
                <InfoButton text="ask_shipping_address" context="subscription" />
                {formik.values.subscription.ask_shipping_address && (
                  <div className={styles.shippingExplain}>
                    <Typography>
                      Se ofrecerán por defecto estas{' '}
                      <a className={styles.addExtraFieldButton} onClick={openDispatchConfig}>
                        opciones de despacho
                      </a>
                      . O puedes definir opciones personalizadas para esta suscripción{' '}
                      <a
                        className={styles.addExtraFieldButton}
                        onClick={() => setShippingModalOpen(true)}
                      >
                        aquí.
                      </a>
                    </Typography>
                  </div>
                )}
              </div>
              {defaultExtraFields?.map((def: DefaultExtraField) => (
                <div key={def.id} className={styles.defaultFieldRow}>
                  <FormControlLabel
                    value={def.kind}
                    control={<Checkbox />}
                    label={def.question}
                    labelPlacement="end"
                    onChange={(e, checked) => changeDefaultField(checked, def.id)}
                    checked={['string', 'boolean'].includes(
                      typeof formik.values.subscription.default_extra_fields?.[def.id]
                    )}
                  />
                  <InfoButton text={def.kind} context="subscription" />
                </div>
              ))}
              {Object.keys(autoExtraFields || {}).map((key) => (
                <div key={key} className={styles.defaultFieldRow}>
                  <FormControlLabel
                    value={autoExtraFields?.[key]}
                    control={<Checkbox />}
                    label={key}
                    labelPlacement="end"
                    onChange={(e, checked) => {
                      changeAutoExtraField(checked, key);
                    }}
                    checked={
                      String(formik.values.subscription?.auto_extra_fields?.[key]) === 'true'
                    }
                  />
                  {key in SUBSCRIPTIONS_DESCRIPTIONS && (
                    <InfoButton text={key} context="subscription" />
                  )}
                </div>
              ))}
              <Divider sx={{ marginBottom: '20px', marginTop: '10px' }} />
            </div>
            {Object.keys(formik.values.subscription.extra_fields || {}).map((id) => {
              return (
                <div key={id} className={styles.extraField}>
                  <TextField
                    label={'Campo adicional'}
                    value={formik.values.subscription.extra_fields?.[id]}
                    placeholder={formik.values.subscription.extra_fields?.[id] || 'Campo adicional'}
                    variant="outlined"
                    onChange={(event: any) =>
                      formik.setFieldValue('subscription.extra_fields', {
                        ...formik.values.subscription.extra_fields,
                        [id]: event.target.value.toString(),
                      })
                    }
                    InputProps={{
                      endAdornment:
                        subscription?.extra_fields_editables[id] === false ? null : (
                          <InputAdornment position="end" className={styles.adornment}>
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              onClick={() => eraseExtraField(id)}
                            />
                          </InputAdornment>
                        ),
                    }}
                    disabled={subscription?.extra_fields_editables[id] === false}
                  />
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Tipo"
                    defaultValue="string"
                    value={formik.values.subscription.extra_fields_kind?.[id]}
                    onChange={(e) => changeFieldKind(e.target.value, id)}
                    className={styles.selector}
                    disabled={subscription?.extra_fields_editables[id] === false}
                  >
                    <MenuItem value={'string'}>Texto libre</MenuItem>
                    <MenuItem value={'selector'}>Selector</MenuItem>
                  </TextField>
                  {formik.values.subscription.extra_fields_kind?.[id] === 'selector' && (
                    <div className={styles.addExtraFieldButton}>
                      <Typography
                        onClick={() => {
                          openOptionsDrawer(id);
                        }}
                      >
                        <a>Agregar opciones</a>
                      </Typography>
                    </div>
                  )}
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label={
                      formik.values.subscription.mandatory_extra_fields?.[id]
                        ? 'Campo obligatorio'
                        : 'Campo no obligatorio'
                    }
                    onChange={(e, checked) => changeMandatoryField(checked, id)}
                    checked={formik.values.subscription.mandatory_extra_fields?.[id]}
                    disabled={subscription?.extra_fields_editables[id] === false}
                  />
                </div>
              );
            })}
            <div className={styles.addExtraFieldButton}>
              <Typography onClick={addExtraField}>
                <a>Agregar otro campo</a>
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
      <div className={styles.submitForm}>
        <Button
          disableElevation
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          className="loader"
        >
          {loading && <CircularProgress size={20} />}
          Guardar
        </Button>
      </div>
      <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(!dialogOpen)}>
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle>Ajusta la imagen por favor</DialogTitle>
          </Grid>
          <Grid item xs={12} className={styles.dialogContainer}>
            <DialogContent>
              <div style={{ position: 'relative', width: '100%', height: 250 }}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="contain"
                />
              </div>
              <div>
                <Slider
                  value={zoom}
                  min={1}
                  max={2}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: 'slider' }}
                />
              </div>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => setDialogOpen(!dialogOpen)}>Cancelar</Button>
              <Button onClick={acceptImage}>Aceptar</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      <Drawer anchor="right" open={optionsDrawerOpen} onClose={() => closeOptionsDrawer()}>
        <div className={styles.drawerContainer}>
          <div className={styles.drawerField}>
            <Typography variant="h6" sx={{ margin: '0 10px 10px' }}>
              Opciones a ofrecer en el selector:
            </Typography>
            <Box width="100%">
              <div className={styles.optionsInput}>
                <TextField
                  label="Agregar opción"
                  variant="outlined"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  className={styles.inputField}
                />
                <IconButton color="primary" sx={{ p: '10px' }} onClick={addExtraFieldOptions}>
                  <Typography color="primary">Agregar</Typography>
                </IconButton>
              </div>
              <Box sx={{ mt: 2 }}>
                {formik.values.subscription.extra_fields_options?.[selectedField]
                  ?.split(',')
                  .map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleDelete(tag, selectedField)}
                      sx={{ marginRight: 1, marginBottom: 1 }}
                    />
                  ))}
              </Box>
            </Box>
            <div className={styles.actions} style={{ marginTop: '50px', display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => {
                  closeOptionsDrawer();
                }}
              >
                Continuar
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <PopUp
        state={{ open: shippingModalOpen, setOpen: setShippingModalOpen }}
        content={
          <ShippingOptions
            regions={formik.values.subscription.shipping_config?.regions}
            communes_by_region={formik.values.subscription.shipping_config?.communes_by_region}
            setConfiguration={saveShippingConfig}
            cancelUpdateShipping={cancelShippingInfo}
          />
        }
      />
      <PopUp
        state={{ open: invoiceModalOpen, setOpen: setInvoiceModalOpen }}
        content={
          <div className={styles.invoiceConfigContent}>
            <Typography variant="h6">
              Configura las sigiuientes opciones para esta suscripción
            </Typography>
            <InvoicingSetupForm formik={invoiceFormik} />
            {subscription?.invoice_configuration && (
              <Typography mt={0} mb={2}>
                <a className={styles.addExtraFieldButton} onClick={deleteSubscriptionInvoiceConfig}>
                  Borrar configuración
                </a>
                . (Se utilizará por defecto la{' '}
                <a className={styles.addExtraFieldButton} onClick={openInvoiceConfig}>
                  configuración empresa
                </a>
                )
              </Typography>
            )}
            <div className={styles.submitForm}>
              <Button
                disableElevation
                size="large"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => invoiceFormik.handleSubmit()}
              >
                {loading ? <CircularProgress size={20} /> : 'Continuar'}
              </Button>
            </div>
          </div>
        }
      />
    </form>
  );
};

export default EditSubscription;
