import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../app/hooks';

// Types
import { ApiObject, Buyer, ApiList, ExtraField } from '../../../app/type';

// API
import { sellerApi } from '../../../common/api';

// Features
import { SellerState, setBuyer } from '../sellerSlice';

// Components
import { Grid, Paper, Typography } from '@mui/material';
import { BuyerForm } from './form';

// Assets
import cStyles from '../../../common/styles/common.module.scss';
import { useSnackbar } from 'notistack';
import { setDocumentTitle, useQuery } from '../../../common/utils';

const BuyerEdit = (): React.ReactElement => {
  const { buyer } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { buyerId, subscriptionId, singlePaymentId } =
    useParams<{ buyerId: string; subscriptionId?: string; singlePaymentId?: string }>();
  const [questions, setQuestions] = useState<ExtraField[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  useEffect(() => {
    if (buyerId !== buyer?.id && buyerId) {
      setLoading(true);
      sellerApi.buyers
        .show(buyerId)
        .then((data: ApiObject<Buyer>) => {
          dispatch(setBuyer(data.data));
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [buyerId, buyer]);

  useEffect(() => {
    setLoading(true);
    if (subscriptionId) {
      sellerApi.subscriptions
        .questions(subscriptionId || '')
        .then((data: ApiList<ExtraField>) => {
          setQuestions(data.data);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    } else if (singlePaymentId) {
      sellerApi.singlePayments
        .questions(singlePaymentId || '')
        .then((data: ApiList<ExtraField>) => {
          setQuestions(data.data);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
    setLoading(false);
  }, [subscriptionId, singlePaymentId]);

  const onSuccess = (
    data: ApiObject<Buyer>,
    extraFields?: { [key: string]: string },
    invoiceInformationId?: string
  ) => {
    dispatch(setBuyer(data.data));
    enqueueSnackbar('Cliente guardado exitosamente', { variant: 'success' });
    if (subscriptionId) {
      setLoading(true);
      sellerApi.subscriptions
        .subscriptionBuyer(subscriptionId, data.data.id, extraFields, invoiceInformationId)
        .catch(console.error)
        .finally(() => {
          setLoading(false);
          history.goBack();
        });
    } else if (singlePaymentId) {
      setLoading(true);
      sellerApi.singlePayments
        .singlePaymentBuyer(singlePaymentId, data.data.id, extraFields, invoiceInformationId)
        .catch(console.error)
        .finally(() => {
          setLoading(false);
          history.goBack();
        });
    } else {
      history.push(`/seller/buyers/${data.data.id}`);
    }
  };

  buyerId ? setDocumentTitle('Editar Cliente') : setDocumentTitle('Crear Cliente');

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper className={cStyles.infoPaper}>
            {/* HEADER */}
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">
                    <b>
                      {buyerId ? 'Editar' : query.add_invoice_information ? 'Agregar' : 'Editar'}{' '}
                      Cliente
                    </b>
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <BuyerForm
              initialData={buyer}
              questions={questions}
              onSuccess={onSuccess}
              loading={loading}
              setLoading={setLoading}
            />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default BuyerEdit;
