// React, packages and api
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { sellerApi } from '../../../common/api';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Slice and utils
import { useDispatch, useSelector } from '../../../app/hooks';
import { SellerState, setProduct } from '../sellerSlice';
import {
  decimalNumberFormatter,
  formatDecimalNumber,
  formatNumber,
  plainNumberFormatter,
  setDocumentTitle,
} from '../../../common/utils';
import { CURRENCIES } from '../../../common/constants/currencies';
import { generateImage } from '../../../common/utils/cropImage';

// MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogActions';
import DialogActions from '@mui/material/DialogActions';
import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

// Styles and assets
import styles from './Edit.module.scss';

// Componentes

// Types
import { ApiObject, Product, Company } from '../../../app/type';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { InfoButton } from '../../../common/components/InfoButton';

const EditProduct = (): React.ReactElement => {
  const { product, company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const dispatch = useDispatch();
  const history = useHistory();
  const { productId } = useParams<{ productId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (productId !== product?.id && productId) {
      sellerApi.products
        .show(productId)
        .then((data: ApiObject<Product>) => {
          dispatch(setProduct(data.data));
        })
        .catch(() => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        });
    }
  }, [productId, product]);

  const onSuccess = (data: ApiObject<Product>) => {
    enqueueSnackbar('Producto guardado exitosamente', { variant: 'success' });
    dispatch(setProduct(data.data));
    history.push(`/seller/products/${data.data.id}`);
  };

  productId ? setDocumentTitle('Editar Producto') : setDocumentTitle('Crear Producto');

  return (
    <>
      <Grid container sx={{ width: 'auto', margin: '2%', marginTop: '0px' }}>
        <Grid item container className={styles.paperHeader}>
          <Grid container item className={styles.paperHeaderContent}>
            <Grid item>
              <Typography variant="h5">{productId ? 'Editar' : 'Crear'} Producto</Typography>
            </Grid>
          </Grid>
          <Grid item className={styles.paperHeaderContent}>
            <Typography variant="body1">
              Podrás inscribir tus <b>productos</b> en tus suscripciones y pagos únicos.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container className={styles.formContainer}>
          <Paper className={styles.infoPaper}>
            <ProductForm initialData={product} onSuccess={onSuccess} company={company} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

interface ProductFormProps {
  initialData: Product | undefined;
  onSuccess: (data: ApiObject<Product>) => void;
  company: Company | undefined;
}

const ProductSchema = yup.object().shape({
  name: yup.string().min(3).max(64).required().label('Nombre'),
  description: yup.string().max(256).label('Descripción'),
  value: yup
    .number()
    .transform((o, v) => {
      if (typeof v == 'string') return parseFloat(v.replace(/,/g, ''));
      else return v;
    })
    .min(0)
    .required()
    .label('Valor'),
});

const ProductForm = (props: ProductFormProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = React.useState<Area>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const initialValues = {
    id: props.initialData?.id || '',
    company_id: props.company?.id,
    name: props.initialData?.name || '',
    description: props.initialData?.description || '',
    value: props.initialData?.value || 0,
    currency: props.initialData?.currency || 'CLP',
    image: props.initialData?.image || '',
    external_id: props.initialData?.external_id || '',
  } as Partial<Omit<Product, 'id'>>;

  const formik = useFormik<Partial<Omit<Product, 'id'>>>({
    initialValues,
    validationSchema: ProductSchema,
    onSubmit: (productInfo: Partial<Omit<Product, 'id'>>, { setErrors }: any) => {
      setLoading(true);
      const request = props.initialData?.id ? sellerApi.products.update : sellerApi.products.create;
      request({ product: productInfo })
        .then(props.onSuccess)
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          } else if (err?.response?.status === 403) {
            enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setLoading(false));
    },
  });

  const [urlImage, setUrlImage] = useState<string>(formik.values.image || '');

  const onUploadImage = (event: any) => {
    setDialogOpen(!dialogOpen);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };
                setUrlImage(URL.createObjectURL(blob));
                newImg.src = url;
                formik.setFieldValue('image_file', blob);
                enqueueSnackbar('Imagen procesada con éxito', { variant: 'success' });
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  return (
    <form
      onKeyPress={(event: any) => event.which == 13 && event.preventDefault()}
      onSubmit={formik.handleSubmit}
    >
      <Paper className={styles.backgroundPaper}>
        <Grid container className={styles.mainGrid}>
          <Grid item xs={6} className={styles.gridItemMargin} sx={{ width: '100%' }}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={9}>
                <Typography className={styles.fieldHeader}>
                  Nombre Producto/Servicio<span className={styles.required}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  required
                  id="name"
                  type="text"
                  autoComplete="name"
                  variant="outlined"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography className={styles.fieldHeader}>
                  Descripción del Producto/Servicio
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  id="description"
                  type="text"
                  autoComplete="description"
                  variant="outlined"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography className={styles.fieldHeader}>
                  Valor del Producto/Servicio<span className={styles.required}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  required
                  id="value"
                  type="text"
                  autoComplete="value"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formik.values.currency === 'CLP' ? '$' : 'UF'}
                      </InputAdornment>
                    ),
                  }}
                  value={
                    formik.values.currency === 'UF'
                      ? formatDecimalNumber(formik.values.value)
                      : formatNumber(formik.values.value)
                  }
                  onChange={(event) =>
                    formik.values.currency === 'UF'
                      ? formik.setFieldValue('value', decimalNumberFormatter(event.target.value))
                      : formik.setFieldValue('value', plainNumberFormatter(event.target.value))
                  }
                  error={formik.touched.value && Boolean(formik.errors.value)}
                  helperText={formik.touched.value && formik.errors.value}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography className={styles.fieldHeader}>
                  Moneda<span className={styles.required}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  required
                  select
                  id="curerncy"
                  name="currency"
                  type="text"
                  autoComplete="Moneda"
                  variant="outlined"
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                  error={formik.touched.currency && Boolean(formik.errors.currency)}
                  helperText={formik.touched.currency && formik.errors.currency}
                >
                  {CURRENCIES.map((curr: string) => (
                    <MenuItem key={curr} value={curr}>
                      {curr}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid item container xs={12} md={6} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                  <Typography className={styles.fieldHeader}>Referencia externa</Typography>
                  <InfoButton text="externalId" context="product" />
                </Grid>
                <TextField
                  fullWidth
                  multiline
                  id="external_id"
                  type="text"
                  autoComplete="external_id"
                  variant="outlined"
                  value={formik.values.external_id}
                  onChange={formik.handleChange}
                  error={formik.touched.external_id && Boolean(formik.errors.external_id)}
                  helperText={formik.touched.external_id && formik.errors.external_id}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className={styles.gridProductImage}>
            <Typography className={styles.photoTitle}>Imagen del producto/servicio</Typography>
            <Paper className={styles.paperProductImage}>
              <Container
                id="imageContainer"
                className={styles.containerProductImage}
                sx={{ backgroundImage: `url(${urlImage})` }}
              >
                <Button className={styles.buttonProductImage} component="label">
                  <CameraAltOutlinedIcon sx={{ color: '#FFFFFF' }} />
                  <input type="file" accept="image/*" hidden id="image" onChange={onUploadImage} />
                </Button>
              </Container>
            </Paper>
            <Typography className={styles.photoSubtitle}>
              Te aconsejamos agregarlo para que tus clientes lo visualicen.
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(!dialogOpen)}>
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle>Ajusta la imagen por favor</DialogTitle>
          </Grid>
          <Grid item xs={12} className={styles.dialogContainer}>
            <DialogContent>
              <div style={{ position: 'relative', width: '100%', height: 250 }}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="contain"
                />
              </div>
              <div style={{ position: 'relative', width: '90%' }}>
                <Slider
                  value={zoom}
                  min={1}
                  max={2}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: 'slider' }}
                />
              </div>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => setDialogOpen(!dialogOpen)}>Cancelar</Button>
              <Button onClick={acceptImage}>Aceptar</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>

      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <Button
          disableElevation
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          className="loader"
        >
          {loading && <CircularProgress size={20} />}
          Guardar
        </Button>
      </Grid>
    </form>
  );
};

export default EditProduct;
