import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../app/hooks';
import { SellerState } from '../sellerSlice';

// Components
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Divider,
  Tooltip,
} from '@mui/material';
import { useFormik, getIn } from 'formik';
import * as yup from 'yup';

import { useSnackbar } from 'notistack';
import { sellerApi } from '../../../common/api';

// Assets
import styles from './CompanySeller.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import { setDocumentTitle } from '../../../common/utils';
import { JumpsellerButtonDesign } from '../../../app/type';
import { Upload as UploadIcon, XCircle as DeleteIcon } from 'react-feather';

const ButtonSchema = yup.object().shape({
  div1: yup.object().shape({
    text: yup.string().max(25, 'Debe tener un largo máximo de 25 caracteres'),
  }),
  div2: yup.object().shape({
    text: yup.string().max(8, 'Debe tener un largo máximo de 8 caracteres'),
  }),
  div3: yup.object().shape({
    text: yup.string().max(35, 'Debe tener un largo máximo de 35 caracteres'),
  }),
});

const JumpsellerSetup = (): React.ReactElement => {
  setDocumentTitle('Configuración Jumpseller');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [jumpsellerButton, setJumpsellerButton] = useState<JumpsellerButtonDesign>();
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const initialValues = {
    div1: {
      bg_color: jumpsellerButton?.div1?.bg_color || '#ffffff',
      font_color: jumpsellerButton?.div1?.font_color || '#4653e3',
      font_weight: jumpsellerButton?.div1?.font_weight || '600',
      text: jumpsellerButton?.div1?.text || 'COMPRAR CON SUSCRIPCIÓN',
    },
    div2: {
      bg_color: jumpsellerButton?.div2?.bg_color || '#4653e3',
      font_color: jumpsellerButton?.div2?.font_color || '#ffffff',
      font_weight: jumpsellerButton?.div2?.font_weight || '700',
      text: jumpsellerButton?.div2?.text || '¡NUEVO!',
    },
    div3: {
      bg_color: jumpsellerButton?.div3?.bg_color || '#4653e3',
      font_color: jumpsellerButton?.div3?.font_color || '#ffffff',
      font_weight: jumpsellerButton?.div3?.font_weight || '700',
      text: jumpsellerButton?.div3?.text || 'Ahorra hasta un 10% suscribiéndote',
    },
    company_id: company?.id || '',
    border_radius: jumpsellerButton?.border_radius || '1',
    border_color: jumpsellerButton?.border_color || '#4653e3',
    border_width: jumpsellerButton?.border_width || '1',
    image: jumpsellerButton?.image || undefined,
  } as JumpsellerButtonDesign;

  const formik = useFormik<JumpsellerButtonDesign>({
    initialValues,
    validationSchema: ButtonSchema,
    onSubmit: (jumpsellerButtonDesign: JumpsellerButtonDesign, { setErrors }: any) => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(jumpsellerButtonDesign).forEach((key) => {
        if (key === 'image' && jumpsellerButtonDesign[key]) {
          // @ts-expect-error: ts(7053)
          formData.append(`data[${key}]`, jumpsellerButtonDesign[key]); // El archivo es un Blob
        } else {
          // @ts-expect-error: ts(7053)
          formData.append(`data[${key}]`, jumpsellerButtonDesign[key]);
        }
      });

      sellerApi.companies // Todo: mover a carpeta companies
        .updateJumpsellerButton(company?.id || '', jumpsellerButtonDesign)
        .then(() => {
          enqueueSnackbar('Información actualizada correctamente', { variant: 'success' });
        })
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelve a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setLoading(false));
    },
    enableReinitialize: true,
  });

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result as string);
        formik.setFieldValue('image', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onDeleteImage = () => {
    setPreviewImage(null);
    formik.setFieldValue('image', undefined);
  };

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    } else if (company) {
      sellerApi.companies.jumpsellerButton(company?.id || '').then((data) => {
        setJumpsellerButton(data.data);
      });
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper className={styles.infoPaper}>
            <Grid container spacing={3} className={styles.mainContainer}>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Diseño botón Suscripción</Typography>
                  <Typography>
                    En esta sección puedes subir una imagen del botón que quieres mostrar en tu
                    tienda diseñado por ti. O puedes basarte en un diseño predefinido y modificar
                    colores y textos. Cualquiera de las dos opciones que selecciones acá se
                    desplegará de forma automática en tu tienda. Debes escoger una sola opción, en
                    caso de que hayas cargado una imagen debes eliminarla para establecer el diseño
                    predefinido de más abajo. Recuerda guardar los cambio antes de salir.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} className={styles.loadImage}>
                <div>
                  <Typography variant="h6">Subir imagen del botón:</Typography>
                  <Typography>
                    Selecciona una imagen desde tu computador en formato .png. Te recomendamos que
                    la imagen sea de las proporciones de 450px de ancho y 100px de alto.
                  </Typography>
                </div>
                <div className={styles.imageContainer}>
                  <Button variant="contained" component="label" className={styles.loadButton}>
                    Cargar imagen &nbsp; <UploadIcon />
                    <input type="file" accept="image/*" hidden onChange={onUploadImage} />
                  </Button>
                  {(previewImage || formik.values.image) && (
                    <div className={styles.imagePreview}>
                      <img
                        src={previewImage ? previewImage : formik.values.image}
                        alt="Vista previa imagen botón"
                        style={{ height: '100px', width: 'auto' }}
                      />
                      <IconButton onClick={onDeleteImage} aria-label="delete" color="error">
                        <Tooltip title="Borrar">
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Typography mt={2} variant="h6">
                  Botón predefinido:
                </Typography>
                <Typography>
                  Si no quieres crear un diseño propio, puedes modificar el de acá abajo:
                </Typography>
              </Grid>
              <div className={styles.components}>
                <Grid item container xs={6} className={styles.inputsSection}>
                  {/* Bordes */}
                  <div>
                    <Typography fontSize={18} fontWeight={600}>
                      Bordes botón:
                    </Typography>
                  </div>
                  <Grid item container className={styles.rowGroup}>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Color:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="border_color"
                          type="color"
                          variant="outlined"
                          value={formik.values.border_color}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Esquinas:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="border_radius"
                          type="number"
                          variant="outlined"
                          InputProps={{ inputProps: { min: 0, max: 90 } }}
                          value={formik.values.border_radius}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Grosor:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="border_width"
                          type="number"
                          variant="outlined"
                          InputProps={{ inputProps: { min: 0, max: 5 } }}
                          value={formik.values.border_width}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </Grid>

                  {/* DIV1 */}
                  <div>
                    <Typography mt={6} fontSize={18} fontWeight={600}>
                      Sección superior:
                    </Typography>
                  </div>
                  <Grid item container className={styles.rowGroup}>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Color fondo:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="div1.bg_color"
                          type="color"
                          variant="outlined"
                          value={formik.values.div1.bg_color}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Color letra:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="div1.font_color"
                          type="color"
                          variant="outlined"
                          value={formik.values.div1.font_color}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </Grid>
                  <div>
                    <Typography mt={1}>Texto (máximo 25 caracteres):</Typography>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      id="div1.text"
                      type="text"
                      variant="outlined"
                      value={formik.values.div1.text}
                      onChange={formik.handleChange}
                      error={
                        getIn(formik.touched, 'div1.text') &&
                        Boolean(getIn(formik.errors, 'div1.text'))
                      }
                      helperText={
                        getIn(formik.touched, 'div1.text') && getIn(formik.errors, 'div1.text')
                      }
                    />
                  </div>

                  {/* DIV2 */}
                  <div>
                    <Typography mt={6} fontSize={18} fontWeight={600}>
                      Sección inferior izquierda:
                    </Typography>
                  </div>
                  <Grid item container className={styles.rowGroup}>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Color fondo:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="div2.bg_color"
                          type="color"
                          variant="outlined"
                          value={formik.values.div2.bg_color}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Color letra:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="div2.font_color"
                          type="color"
                          variant="outlined"
                          value={formik.values.div2.font_color}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </Grid>
                  <div>
                    <Typography mt={1}>Texto (máximo 8 caracteres):</Typography>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      id="div2.text"
                      type="text"
                      variant="outlined"
                      value={formik.values.div2.text}
                      onChange={formik.handleChange}
                      error={
                        getIn(formik.touched, 'div2.text') &&
                        Boolean(getIn(formik.errors, 'div2.text'))
                      }
                      helperText={
                        getIn(formik.touched, 'div2.text') && getIn(formik.errors, 'div2.text')
                      }
                    />
                  </div>

                  {/* DIV3 */}
                  <div>
                    <Typography mt={6} fontSize={18} fontWeight={600}>
                      Sección inferior derecha:
                    </Typography>
                  </div>
                  <Grid item container className={styles.rowGroup}>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Color fondo:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="div3.bg_color"
                          type="color"
                          variant="outlined"
                          value={formik.values.div3.bg_color}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.group}>
                      <div>
                        <Typography mt={1}>Color letra:</Typography>
                      </div>
                      <div>
                        <TextField
                          fullWidth
                          id="div3.font_color"
                          type="color"
                          variant="outlined"
                          value={formik.values.div3.font_color}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </Grid>
                  <div>
                    <Typography mt={1}>Texto (máximo 35 caracteres):</Typography>
                  </div>
                  <div>
                    <TextField
                      fullWidth
                      id="div3.text"
                      type="text"
                      variant="outlined"
                      value={formik.values.div3.text}
                      onChange={formik.handleChange}
                      error={
                        getIn(formik.touched, 'div3.text') &&
                        Boolean(getIn(formik.errors, 'div3.text'))
                      }
                      helperText={
                        getIn(formik.touched, 'div3.text') && getIn(formik.errors, 'div3.text')
                      }
                    />
                  </div>
                </Grid>

                {/* BOTÓN */}
                <Grid item xs={6} className={styles.shopifyButtonContainer}>
                  <div
                    className={styles.shopifyButton}
                    style={{
                      borderRadius: formik.values.border_radius,
                      borderColor: formik.values.border_color,
                      borderWidth: parseInt(formik.values.border_width),
                    }}
                  >
                    <div
                      className={styles.div1}
                      style={{
                        backgroundColor: formik.values.div1.bg_color,
                        color: formik.values.div1.font_color,
                        fontWeight: parseInt(formik.values.div1.font_weight),
                      }}
                    >
                      <p>{formik.values.div1.text}</p>
                      <svg
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.80078 7L1.80078 0L0.400782 1.4L6.00078 7L0.400782 12.6L1.80078 14L8.80078 7Z"
                          fill={formik.values.div1.font_color}
                        />
                      </svg>
                    </div>
                    <div className={styles.bottomRow}>
                      <div
                        className={styles.div2}
                        style={{
                          backgroundColor: formik.values.div2.bg_color,
                          color: formik.values.div2.font_color,
                          fontWeight: parseInt(formik.values.div2.font_weight),
                        }}
                      >
                        {formik.values.div2.text}
                      </div>
                      <div
                        className={styles.div3}
                        style={{
                          backgroundColor: formik.values.div3.bg_color,
                          color: formik.values.div3.font_color,
                          fontWeight: parseInt(formik.values.div3.font_weight),
                        }}
                      >
                        {formik.values.div3.text}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="center" alignItems="center">
              <Button
                disableElevation
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                className="loader"
              >
                {loading && <CircularProgress size={20} />}
                Guardar
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default JumpsellerSetup;
