import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, TextField, Button, Box, CircularProgress } from '@mui/material';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { setClient, setCompany } from './clientSlice';
import { useDispatch } from '../../app/hooks';
import { clientApi } from '../../common/api';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { ApiObject, Client, Company } from '../../app/type';
import { RootState } from '../../app/store';
import { SessionState } from '../../features/session/sessionSlice';
import Avatar from '../../common/components/Avatar';

import Recaptcha from '../../common/components/Recaptcha';

import styles from './Client.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { clientLoginSuccess } from '../session/sessionSlice';
import { useQuery } from '../../common/utils';

const LoginSchema = yup.object().shape({
  email: yup.string().email().required().label('Correo'),
});

const ClientLogin = (): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(true);
  const [companyPortal, setCompanyPortal] = useState<Company>();
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const { clientLogged } = useSelector((state: RootState): SessionState => state.session);
  const recaptchaRef = useRef<{ execute: () => Promise<string> }>();

  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setEmail(formik.values.email);
      setLoading(true);
      recaptchaRef.current
        ?.execute()
        .then(() => {
          clientApi.client
            .sendAuthenticationCode(formik.values.email, companyPortal?.id || '')
            .then(() => {
              setCodeSent(true);
              enqueueSnackbar('Código enviado', { variant: 'success' });
            })
            .catch(() => {
              setCodeSent(true);
              enqueueSnackbar('Código enviado', { variant: 'info' });
            })
            .finally(() => {
              setLoading(false);
              formik.resetForm();
            });
        })
        .catch(() => enqueueSnackbar('reCAPTCHA fallido', { variant: 'error' }));
    },
  });

  const resendEmail = () => {
    setLoading(true);
    clientApi.client
      .sendAuthenticationCode(email, companyPortal?.id || '')
      .then(() => {
        setCodeSent(true);
        enqueueSnackbar('Código enviado', { variant: 'info' });
      })
      .catch((error) => {
        enqueueSnackbar('Ocurrió un error al enviar el código. Intenta nuevamente.', {
          variant: 'error',
        });
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateCode = () => {
    setLoading(true);
    recaptchaRef.current
      ?.execute()
      .then(() => {
        clientApi.client
          .validateAuthenticationCode(email, code, companyPortal?.id || '')
          .then((data: ApiObject<Client>) => {
            dispatch(clientLoginSuccess());
            dispatch(setCompany(data.data.company));
            dispatch(setClient(data.data));
            history.replace('/client/products');
          })
          .catch((error) => {
            enqueueSnackbar('Código inválido. Asegúrate de ingresar el código correcto.', {
              variant: 'error',
            });
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => enqueueSnackbar('reCAPTCHA fallido', { variant: 'error' }));
  };

  useEffect(() => {
    if (clientLogged) {
      history.replace('/client/products');
    } else {
      if (query.company_id) {
        setLoadingCompany(true);
        clientApi.companies
          .show(query.company_id)
          .then((data: ApiObject<Company>) => {
            setCompanyPortal(data.data);
            if (!query.client_id) setLoadingCompany(false);
          })
          .catch((e: any) => {
            console.error(e);
            setCompanyPortal(undefined);
            setLoadingCompany(false);
          });
        if (query.token_expired) {
          enqueueSnackbar('Sesión expirada. Ingresa nuevamente tu correo', {
            variant: 'info',
          });
        }
      } else {
        setCompanyPortal(undefined);
      }
      if (query.client_id) {
        clientApi.client
          .authenticateClientById(query.client_id, query.company_id || '')
          .then((data: ApiObject<Client>) => {
            dispatch(clientLoginSuccess());
            dispatch(setCompany(data.data.company));
            dispatch(setClient(data.data));
            history.replace('/client/products');
          })
          .finally(() => {
            setLoadingCompany(false);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (companyPortal) {
      if (companyPortal.subdomain) {
        const subdomain = document.location.host.split('.')[0];
        if (subdomain !== 'app' && subdomain !== companyPortal.subdomain)
          history.replace('/client/error');
      }
    }
  }, [companyPortal]);

  return (
    <div
      className={styles.appContainer}
      style={{
        background: companyPortal?.payment_design?.background_color
          ? companyPortal?.payment_design?.background_color
          : '#4653e3',
      }}
    >
      {loadingCompany ? (
        <div className={styles.loaderContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
            alt="Logo Zafepay"
          />
        </div>
      ) : (
        <div className={styles.loginContainer}>
          {companyPortal && !companyPortal.disabled_client_portal ? (
            <Fragment>
              <Avatar
                className={styles.logo}
                img={companyPortal?.image || undefined}
                context="company"
              />
              <div className={styles.title}>
                <Typography>Iniciar sesión</Typography>
              </div>
              {codeSent ? (
                <Fragment>
                  <Recaptcha innerRef={recaptchaRef} />
                  <div className={styles.loginInfoBox}>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      style={{
                        color: companyPortal?.payment_design?.background_color
                          ? companyPortal?.payment_design?.background_color
                          : '#4653e3',
                      }}
                    />
                    <Typography>
                      Te hemos enviado el código al correo: <strong>{email}</strong>. ¿No te ha
                      llegado? Asegúrate de que el correo que ingresaste es el correcto, si es el
                      correcto, haz click{' '}
                      <strong>
                        <u className={styles.resendEmailLink} onClick={resendEmail}>
                          aquí
                        </u>
                      </strong>{' '}
                      para enviarlo nuevamente
                    </Typography>
                  </div>
                  <div className={styles.inputContainer}>
                    <Typography sx={{ fontWeight: 600, marginBottom: '10px' }}>Código</Typography>
                    <TextField
                      fullWidth
                      id="code"
                      type="text"
                      variant="outlined"
                      value={code}
                      onChange={(event: any) => setCode(event.target.value)}
                    />
                  </div>
                  <div
                    className={styles.button}
                    onClick={validateCode}
                    style={{
                      background: companyPortal?.payment_design?.background_color
                        ? companyPortal?.payment_design?.background_color
                        : '#4653e3',
                    }}
                  >
                    {loading ? (
                      <div className={styles.whiteSpinnerContainer}>
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress size={'20px'} />
                        </Box>
                      </div>
                    ) : (
                      <Typography>Ingresar</Typography>
                    )}
                  </div>
                  <div
                    className={styles.backLink}
                    onClick={() => {
                      setCodeSent(false);
                      setCode('');
                    }}
                    style={{
                      color: companyPortal?.payment_design?.background_color
                        ? companyPortal?.payment_design?.background_color
                        : '#4653e3',
                      textDecorationColor: companyPortal?.payment_design?.background_color
                        ? companyPortal?.payment_design?.background_color
                        : '#4653e3',
                    }}
                  >
                    <Typography>Volver</Typography>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Recaptcha innerRef={recaptchaRef} />
                  <div className={styles.loginInfoBox}>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      style={{
                        color: companyPortal?.payment_design?.background_color
                          ? companyPortal?.payment_design?.background_color
                          : '#4653e3',
                      }}
                    />
                    <Typography>
                      Ingresa tu correo correctamente para que se te haga envío del código de
                      ingreso.
                    </Typography>
                  </div>
                  <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
                    <div className={styles.inputContainer}>
                      <Typography sx={{ fontWeight: 600, marginBottom: '10px' }}>Correo</Typography>
                      <TextField
                        fullWidth
                        id="email"
                        type="text"
                        autoComplete="email"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </div>
                    <Button
                      className={styles.button}
                      type="submit"
                      disabled={loading}
                      style={{
                        background: companyPortal?.payment_design?.background_color
                          ? companyPortal?.payment_design?.background_color
                          : '#4653e3',
                      }}
                    >
                      {loading ? (
                        <div className={styles.whiteSpinnerContainer}>
                          <Box sx={{ display: 'flex' }}>
                            <CircularProgress size={'20px'} />
                          </Box>
                        </div>
                      ) : (
                        <Typography>Enviar código</Typography>
                      )}
                    </Button>
                  </form>
                </Fragment>
              )}
            </Fragment>
          ) : companyPortal?.disabled_client_portal ? (
            <div className={styles.warningConataienr}>
              <img
                src={'https://storage.googleapis.com/onlypays-public/assets/images/warning.svg'}
                alt="Warning"
              />
              <div className={styles.titleWarning}>
                <Typography>Sección deshabilitada</Typography>
              </div>
              <Typography variant="h6" mt={2}>
                El módulo de clientes se encuentra deshabilitado.
              </Typography>
            </div>
          ) : (
            <div className={styles.warningConataienr}>
              <img
                src={'https://storage.googleapis.com/onlypays-public/assets/images/warning.svg'}
                alt="Warning"
              />
              <div className={styles.titleWarning}>
                <Typography>URL inválida</Typography>
              </div>
              <Typography variant="h6" mt={2}>
                Abre el link desde el correo que te compartió la empresa para ingresar a tu portal.
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientLogin;
