import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../app/hooks';
import { formatCurrency, setDocumentTitle } from '../../../common/utils';
import { useSnackbar } from 'notistack';

import { sellerApi } from '../../../common/api';
import { useMediaQuery, Typography, Button, Drawer } from '@mui/material';

import { Statement, Buyer, InvoiceDocument } from '../../../app/type';
import { SellerState, setBuyer } from '../sellerSlice';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';

import styles from './Index.module.scss';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import variables from '../../../common/styles/variables.module.scss';
import { faMoneyBill1, faXmark } from '@fortawesome/free-solid-svg-icons';
import { PAYABLE_TYPES } from '../../../common/constants/statements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableList from '../../../common/components/TableList';

const StatementPage = (): React.ReactElement => {
  setDocumentTitle('Pagos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStatement, setSelectedStatement] = useState<Statement>();
  const [openRefundPopup, setOpenRefundPopup] = useState<boolean>(false);
  const [documentsDrawerOpen, setDocumentsDrawerOpen] = useState<boolean>(false);
  const reloadRef = useRef<{ reloadResource: () => void }>();

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    }
  }, [company]);

  const onItem = (res: Buyer | undefined) => dispatch(setBuyer(res));

  const refundStatementAction = (statement: Statement) => {
    setOpenRefundPopup(true);
    setSelectedStatement(statement);
  };

  const refundStatement = (status: string) => {
    setOpenRefundPopup(false);
    enqueueSnackbar('Estamos procesando tu solicitud', {
      variant: 'success',
    });
    sellerApi.statements
      .refund(selectedStatement?.id || '', selectedStatement?.group_id, status)
      .then((response) => {
        enqueueSnackbar(
          response.message === 'kushki'
            ? 'La devolución está siendo procesada por el proveedor'
            : status === 'refund'
            ? 'Pago reembolsado exitosamente'
            : 'Solicitud procesada con éxito',
          {
            variant: 'success',
          }
        );
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.data.message === 'fintoc not allowed')
          text = 'Fintoc no está habilitado para realizar devoluciones';
        else if (reason.response.data.message === 'expired_time')
          text = 'Han pasado más de 14 días desde que se hizo el pago';
        else if (reason.response.data.message === 'exceed_amount')
          text = 'No tienes saldo suficiente para devolver el monto';
        else if (reason.response.data.message === 'not_credit_card')
          text = 'Solo se pueden hacer devoluciones de tarjetas de crédito';
        else if (reason.response.status === 403)
          text = 'Necesitas ser administrador para realizar esta acción';
        else if (reason.response.data.message === 'Klap not allowed')
          text = 'Klap no está habilitado para realizar devoluciones';
        enqueueSnackbar(text || 'No pudimos devolver el pago, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedStatement(undefined);
      });
  };

  const statementInoviceDocuments = (statement: Statement) => {
    setSelectedStatement(statement);
    setDocumentsDrawerOpen(true);
  };

  const generateDocument = (invoiceDocumentId: string) => {
    sellerApi.statements
      .retryInvoiceDocument(selectedStatement?.id || '', invoiceDocumentId)
      .then(() => {
        setDocumentsDrawerOpen(false);
        reloadRef?.current?.reloadResource?.();
        enqueueSnackbar('Se intentará crear el documento', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Error al cargar los detalles de la cuota', {
          variant: 'error',
        });
      });
  };

  return (
    <Fragment>
      <ResourceList
        title="Pagos"
        queryFields="Nombre, correo, servicio"
        getResourceList={sellerApi.statements.list}
        resourceParent={company}
        innerRef={reloadRef}
        listHeaders={[
          { key: 'service_name', label: 'Servicio' },
          { key: 'buyer', label: 'Cliente' },
          { key: 'payable_type', label: 'Tipo' },
          { key: 'value', label: 'Valor' },
          { key: 'due_date', label: 'Fecha de vencimiento' },
          { key: 'payment_date', label: 'Fecha de pago' },
          { key: 'status', label: 'Estado' },
        ]}
        listMobileHeaders={[{ key: 'service_name' }, { key: 'buyer' }]}
        listColumns={{
          service_name: (res: Statement) =>
            isMobile ? (
              <Typography variant="subtitle1">{res.service_name}</Typography>
            ) : (
              res.service_name
            ),
          buyer: (res: Statement) =>
            isMobile ? (
              <Typography variant="subtitle1">{res.buyer?.name}</Typography>
            ) : (
              res.buyer?.name
            ),
          payable_type: (res: Statement) => PAYABLE_TYPES[res.payable_type],
          value: (res: Statement) =>
            formatCurrency(res.currency === 'UF' ? res.external_amount : res.amount, res.currency),
          status: (res: Statement) => <StatusLabel type="statement" status={res.status} />,
          due_date: (res: Statement) => res.due_date,
          payment_date: (res: Statement) => res.payment_date,
        }}
        listActionsHeaders={(res) => [
          { key: 'show', label: 'Ver Cliente', icon: faEye },
          ['Fintoc', 'Método externo'].includes(res?.payment_type_translated || 'none') ||
          [
            'refunded',
            'expired',
            'pending',
            'processing_refund',
            'refunded_manually',
            'processing_payment',
          ].includes(res?.status || '')
            ? null
            : { key: 'refund', label: 'Devolución', icon: faMoneyBill1 },
          res?.invoice_documents && res?.invoice_documents.length > 0
            ? { key: 'invoice_documents', label: 'Ver documento', icon: faEye }
            : null,
        ]}
        listActions={{
          show: (res: Statement) => {
            onItem(res.buyer);
            history.push(`/seller/buyers/${res.buyer?.id}`);
          },
          refund: (res: Statement) => refundStatementAction(res),
          invoice_documents: (res: Statement) => statementInoviceDocuments(res),
        }}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.statements.statementsExcel('company', parentId, query, filterParams)
        }
        filtersKey="statement_index"
        persistFilters
      />
      <PopUp
        state={{ open: openRefundPopup, setOpen: setOpenRefundPopup }}
        title={
          <Typography variant="h6" align="center">
            Devolución pago
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Para devolver un pago tienes dos opciones, que la plataforma procese la devolución a
              través del medio de pago con el que se realizó el pago. O marcar el pago como
              &quot;Devuelto manualmente&quot; y que tú gestiones la devolución por fuera de la
              plataforma.
            </Typography>
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenRefundPopup(false)} variant="outlined" key={1}>
            Cancelar
          </Button>,
          <Button
            color="info"
            variant="contained"
            onClick={() => refundStatement('refund')}
            key={2}
          >
            Devolver cuota
          </Button>,
          <Button
            onClick={() => {
              refundStatement('refund_manually');
            }}
            color="info"
            variant="contained"
            key={3}
          >
            Marcar como devuelta
          </Button>,
        ]}
      />
      <Drawer
        anchor="right"
        open={documentsDrawerOpen}
        onClose={() => setDocumentsDrawerOpen(false)}
        className={styles.drawerContainer}
      >
        <div className={styles.drawer}>
          <div className={styles.closePopup}>
            <div className={styles.xIcon} onClick={() => setDocumentsDrawerOpen(false)}>
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: '#5f5f5f', width: '20px', height: '20px' }}
              />
            </div>
          </div>
          <Typography variant="h6">Documento tributario emitido</Typography>
          <TableList
            resources={selectedStatement?.invoice_documents || []}
            headers={[
              { key: 'date', label: 'Fecha' },
              { key: 'kind', label: 'Documento' },
              { key: 'external_status', label: 'Estado' },
              { key: 'actions', label: 'Acciones' },
            ]}
            columns={{
              date: (res: InvoiceDocument) => res.created_at,
              kind: (res: InvoiceDocument) => res.kind,
              external_status: (res: InvoiceDocument) => (
                <StatusLabel
                  type="invoiceDocument"
                  status={
                    ['no_folios', 'missing_data'].includes(res.status)
                      ? res.status
                      : res.external_status
                  }
                />
              ),
              actions: (res: InvoiceDocument) =>
                res.link ? (
                  <Typography
                    onClick={() => window.open(res.link, '_blank')}
                    sx={{ cursor: 'pointer' }}
                    fontSize={14}
                  >
                    <FontAwesomeIcon icon={faEye} style={{ marginRight: '10px' }} />
                    Ver documento
                  </Typography>
                ) : ['no_folios', 'missing_data'].includes(res.status) ? (
                  <Typography
                    onClick={() => generateDocument(res.id)}
                    sx={{ cursor: 'pointer' }}
                    fontSize={14}
                  >
                    <u>Reintentar</u>
                  </Typography>
                ) : (
                  '-'
                ),
            }}
          />
        </div>
      </Drawer>
    </Fragment>
  );
};

export default StatementPage;
