import React, { Fragment, useEffect, useState } from 'react';

import { Button, Grid, GridSize, Typography, Tooltip, IconButton } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faArrowDownLong, faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '../utils';

interface InfoBoxProps {
  title: string;
  data?: number | string | Date;
  function?: (event?: any) => void;
  kind: 'info1' | 'info2' | 'link';
  style?: string;
  image?: string;
  icon?: React.ReactElement;
  width?: number;
  informationTooltip?: string;
  trend?: number;
  trendCurrency?: '%' | 'CLP';
  tooltipMessage?: string;
  dataTooltip?: (string | number)[][];
}

const InfoBox = (props: InfoBoxProps): React.ReactElement => {
  const [showDataTooltip, setShowDataTooltip] = useState<boolean>(false);
  const gridItemWidth = props.width || 4;

  const renderTooltipContent = () => {
    return (
      <ul style={{ paddingLeft: '20px' }}>
        {props.dataTooltip
          ?.filter(([, value]) => typeof value === 'number' && value > 0)
          .map(([label, value], index) => (
            <li key={index} style={{ margin: 0 }}>
              <Typography>
                {label}: {formatCurrency(value)}
              </Typography>
            </li>
          ))}
      </ul>
    );
  };

  useEffect(() => {
    if (props.dataTooltip) {
      const hasNonZeroValues = props.dataTooltip.some(
        ([, value]) => typeof value === 'number' && value !== 0
      );
      setShowDataTooltip(hasNonZeroValues);
    }
  }, [props.dataTooltip]);

  return (
    <Grid item xs={gridItemWidth as GridSize}>
      <div className={props.style}>
        {props.kind == 'info1' && (
          <Fragment>
            <div className="upFooter">
              <div className="title1">
                <div className="titleIcon1">{props.icon}</div>
                <Typography variant="h6">{props.title}</Typography>
              </div>
              <div className="mainData1">
                <Typography variant="h3">{props.data}</Typography>
                {props.trend && (
                  <div className={`trendContainer ${props.trend < 0 ? 'error' : 'success'}`}>
                    {props.trend < 0 ? (
                      <FontAwesomeIcon icon={faArrowDownLong} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUpLong} />
                    )}{' '}
                    <Typography variant="h5">{props.trend}</Typography>
                  </div>
                )}
              </div>
            </div>
            <div className="tooltip">
              {props.informationTooltip ? (
                <Tooltip title={props.informationTooltip}>
                  <IconButton>
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
            <div className="footer" />
          </Fragment>
        )}
        {props.kind == 'info2' && (
          <Fragment>
            <div className="upFooter">
              <div className="title2">
                {props.tooltipMessage ? (
                  <Tooltip
                    title={props.tooltipMessage}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'white',
                          color: '#1d4c69',
                          maxWidth: '200px',
                          padding: '10px',
                          fontSize: '14px',
                          border: '2px solid #F0F0F0',
                        },
                      },
                    }}
                  >
                    <div className="titleIcon2" style={{ cursor: 'pointer' }}>
                      {props.icon}
                    </div>
                  </Tooltip>
                ) : (
                  <div className="titleIcon2">{props.icon}</div>
                )}
                <Typography variant="subtitle1">{props.title}</Typography>
              </div>
              <div className="mainData2">
                {showDataTooltip ? (
                  <Tooltip
                    title={renderTooltipContent()}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'white',
                          color: '#1d4c69',
                          padding: '10px',
                          fontSize: '14px',
                          border: '2px solid #F0F0F0',
                        },
                      },
                    }}
                  >
                    <Typography variant="h3" style={{ cursor: 'pointer' }}>
                      {props.data}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="h3">{props.data}</Typography>
                )}

                {props.trend && (
                  <div className={`trendContainer ${props.trend < 0 ? 'error' : 'success'}`}>
                    {props.trend < 0 ? (
                      <FontAwesomeIcon icon={faArrowDownLong} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowUpLong} />
                    )}{' '}
                    <Typography variant="h5">{`${props.trend}${
                      props.trendCurrency || ''
                    }`}</Typography>
                  </div>
                )}
              </div>
            </div>
            <div className="tooltip">
              {props.informationTooltip ? (
                <Tooltip title={props.informationTooltip}>
                  <IconButton>
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
            <div className="footer" />
          </Fragment>
        )}
        {props.kind == 'link' && (
          <div className="main">
            <img src={props.image} className="mainImage" />
            <Button variant="contained" onClick={props.function}>
              {props.title}
            </Button>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default InfoBox;
