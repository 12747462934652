import React, { useMemo, useState, useEffect, Fragment } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Grid, TextField, Typography, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { formatRUT, validateRut, downcase } from '../../../common/utils';

import styles from './Show.module.scss';
import {
  ShippingInformation,
  InvoiceConfiguration,
  PaymentLinkConfiguration,
  InvoiceInformation,
  ExtraField,
  Subscription,
  SubscriptionBuyer,
} from '../../../app/type';

import { COMMUNES_BY_REGION } from '../../../common/constants/communes';
import { sellerApi } from '../../../common/api';
import { REGIONS } from '../../../common/constants/regions';
import { useSelector } from 'react-redux';
import { SellerState } from '../sellerSlice';

interface ChangeSubscriptionFormProps {
  selectedSubscription: Subscription;
  subBuyerState: SubscriptionBuyer;
  onSuccess: (data: any) => void;
  setLoading: (loading: boolean) => void;
  questions: ExtraField[];
  formRef: React.RefObject<HTMLFormElement>;
}

type SubmitData = {
  shipping_address?: ShippingInformation;
  invoice_information?: InvoiceInformation;
  extra_fields?: { [key: string]: string };
};

export const ChangeSubscriptionForm = (props: ChangeSubscriptionFormProps): React.ReactElement => {
  const { selectedSubscription, subBuyerState, onSuccess, setLoading, formRef } = props;
  const { subscription } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const [shippingConfiguration, setShippingConfiguration] = useState<PaymentLinkConfiguration>();
  const [invoiceConfiguration, setInvoiceConfiguration] = useState<InvoiceConfiguration>();
  const [invoiceDocument, setInvoiceDocument] = useState<string>();
  const [extraFields, setExtraFields] = useState<{ [key: string]: string }>({});
  const [invalidRut, setInvalidRut] = useState<boolean>(false);

  // Shipping Schema
  const buyerShippingSchema = useMemo(() => {
    return yup.object().shape({
      address1: yup.string().required().label('Dirección'),
      region: yup.string().required().label('Región'),
      commune: yup.string().required().label('Comuna'),
    });
  }, []);

  // Invoice Schema
  const invoiceInformationSchema = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const conditionalRequired = (fieldName: string) =>
      invoiceDocument === 'invoice'
        ? yup.string().required().label(fieldName)
        : yup.string().label(fieldName);
    const rutConditionalRequired = (fieldName: string) =>
      invoiceDocument === 'invoice' ||
      (invoiceDocument === 'receipt' && invoiceConfiguration?.ask_rut)
        ? yup.string().required().label(fieldName)
        : yup.string().label(fieldName);

    return yup.object().shape({
      region: conditionalRequired('Región'),
      commune: conditionalRequired('Comuna'),
      address: conditionalRequired('Dirección'),
      rut: rutConditionalRequired('Rut').test(
        'is-valid-rut',
        'Rut ingresado inválido',
        (value) => !value || validateRut(value)
      ),
      business_name: conditionalRequired('Razón social'),
      activity: conditionalRequired('Giro'),
      email: yup
        .string()
        .email()
        .matches(emailRegex, 'Email inválido, no puede contener caracteres especiales')
        .label('Email de contacto'),
    });
  }, [invoiceDocument, invoiceConfiguration]);

  // Shipping formik
  const shippingFormik = useFormik<ShippingInformation>({
    initialValues: {
      address1: subBuyerState.buyer.shipping_address?.address1 || '',
      address2: subBuyerState.buyer.shipping_address?.address2 || '',
      country: subBuyerState.buyer.shipping_address?.country || 'Chile',
      commune: subBuyerState.buyer.shipping_address?.commune || '',
      region: subBuyerState.buyer.shipping_address?.region || '',
      phone: subBuyerState.buyer.shipping_address?.phone || '',
    },
    validationSchema: buyerShippingSchema,
    onSubmit: (shippingInfo) => {
      handleSubmit({ shipping_address: shippingInfo });
    },
  });

  // Invoice formik
  const invoiceInformationFormik = useFormik<InvoiceInformation>({
    initialValues: {
      id: '',
      region: '',
      commune: '',
      address: '',
      rut: '',
      business_name: '',
      activity: '',
      email: '',
    },
    validationSchema: invoiceInformationSchema,
    onSubmit: (invoiceInfo) => {
      handleSubmit({ invoice_information: invoiceInfo });
    },
  });

  const handleExtraFieldChange = (event: any, id: string, question: string) => {
    if (['rut', 'rut empresa'].includes(downcase(question))) {
      const rut = formatRUT(event.target.value);
      setExtraFields({
        ...extraFields,
        [id]: rut,
      });
      setInvalidRut(!validateRut(rut));
    } else {
      setExtraFields({
        ...extraFields,
        [id]: event.target.value,
      });
    }
  };

  const validateRutExtraField = () => {
    const rutExtraFields = (props.questions || {}).filter(
      (object) => downcase(object.question) === 'rut'
    );
    if (rutExtraFields.length === 0) return true;
    let validRuts = true;
    rutExtraFields.forEach((ef) => {
      const valid = validateRut(extraFields[ef.id]);
      if (!valid) {
        setInvalidRut(true);
        validRuts = false;
      }
    });
    return validRuts;
  };

  const validateAndSubmit = async () => {
    if (!validateRutExtraField()) {
      return;
    }

    if (selectedSubscription.ask_shipping_address) {
      const isValid = await shippingFormik.validateForm();
      if (Object.keys(isValid).length > 0) {
        shippingFormik.handleSubmit();
        return;
      }
    }

    if (selectedSubscription.emit_document && invoiceConfiguration) {
      const isValid = await invoiceInformationFormik.validateForm();
      if (Object.keys(isValid).length > 0) {
        invoiceInformationFormik.handleSubmit();
        return;
      }
    }

    const formData = {
      extra_fields: extraFields,
      ...(selectedSubscription.ask_shipping_address && {
        shipping_address: shippingFormik.values,
      }),
      ...(selectedSubscription.emit_document && {
        invoice_information: {
          ...invoiceInformationFormik.values,
          document: invoiceDocument,
        },
      }),
    };

    setLoading(true);
    try {
      onSuccess(formData);
    } catch (error: any) {
      enqueueSnackbar('Ocurrió un error al cambiar la suscripción', { variant: 'error' });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = (data: SubmitData) => {
    validateAndSubmit();
  };

  useEffect(() => {
    if (selectedSubscription) {
      if (selectedSubscription.ask_shipping_address) {
        sellerApi.subscriptions
          .shippingConfiguration(selectedSubscription.id)
          .then((data) => setShippingConfiguration(data.data))
          .catch(console.error);
      }
      if (selectedSubscription.emit_document) {
        sellerApi.subscriptions
          .invoiceConfiguration(selectedSubscription.id)
          .then((data) => {
            setInvoiceConfiguration(data.data);
            setInvoiceDocument(data.data.document === 'invoice' ? 'invoice' : 'receipt');
          })
          .catch(console.error);
      }
    }
  }, [selectedSubscription]);

  useEffect(() => {
    if (subscription?.all_extra_fields && selectedSubscription?.extra_fields && props.questions) {
      const matchingFields: { [key: string]: string } = {};
      props.questions.forEach((question) => {
        const questionText = question.question.toLowerCase();

        const matchingPreviousValue = Object.keys(subscription.all_extra_fields).find(
          (key) => subscription.all_extra_fields[key].toLowerCase() === questionText
        );

        if (matchingPreviousValue) {
          matchingFields[question.id] = subBuyerState.extra_fields[matchingPreviousValue];
        }
      });
      if (Object.keys(matchingFields).length > 0) {
        setExtraFields((prev) => ({
          ...prev,
          ...matchingFields,
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (subBuyerState.payable_invoice_information) {
      setInvoiceDocument(subBuyerState.payable_invoice_information.document);
      invoiceInformationFormik.setValues({
        id: subBuyerState.payable_invoice_information.invoice_information?.id || '',
        region: subBuyerState.payable_invoice_information.invoice_information?.region || '',
        commune: subBuyerState.payable_invoice_information.invoice_information?.commune || '',
        address: subBuyerState.payable_invoice_information.invoice_information?.address || '',
        rut: subBuyerState.payable_invoice_information.invoice_information?.rut || '',
        business_name:
          subBuyerState.payable_invoice_information.invoice_information?.business_name || '',
        activity: subBuyerState.payable_invoice_information.invoice_information?.activity || '',
        email: subBuyerState.payable_invoice_information.invoice_information?.email || '',
      });
    } else {
      const firstInvoiceInfo = subBuyerState.buyer.invoice_informations?.[0];
      if (firstInvoiceInfo && firstInvoiceInfo.activity) setInvoiceDocument('invoice');
      invoiceInformationFormik.setValues({
        id: firstInvoiceInfo?.id || '',
        region: firstInvoiceInfo?.region || '',
        commune: firstInvoiceInfo?.commune || '',
        address: firstInvoiceInfo?.address || '',
        rut: firstInvoiceInfo?.rut || '',
        business_name: firstInvoiceInfo?.business_name || '',
        activity: firstInvoiceInfo?.activity || '',
        email: firstInvoiceInfo?.email || '',
      });
    }
  }, [subBuyerState]);

  return (
    <form
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        validateAndSubmit();
      }}
      className={styles.form}
    >
      <Grid container spacing={2}>
        {props.questions.map((object: ExtraField) => (
          <Grid item xs={12} md={6} key={object.id} className={styles.inputContainer}>
            {object.kind === 'string' ? (
              <TextField
                fullWidth
                required={object.mandatory}
                id={object.question}
                name={object.question}
                label={object.question}
                type="text"
                autoComplete="formik.values.extra_fields[key]"
                variant="outlined"
                value={extraFields[object.id] || ''}
                onChange={(event) => handleExtraFieldChange(event, object.id, object.question)}
                error={invalidRut}
                helperText={invalidRut ? 'Rut inválido' : ''}
              />
            ) : (
              <TextField
                fullWidth
                required={object.mandatory}
                id={object.question}
                name={object.question}
                label={object.question}
                select
                variant="outlined"
                value={extraFields[object.id] || ''}
                onChange={(event) =>
                  setExtraFields({
                    ...extraFields,
                    [object.id]: event.target.value,
                  })
                }
              >
                {object.selector_options?.split(',').map((option: string) => (
                  <MenuItem key={`${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        ))}
        {selectedSubscription?.ask_shipping_address && (
          <Fragment>
            <Grid item xs={6} className={styles.inputContainer}>
              <TextField
                fullWidth
                required
                select
                id="region"
                label="Región"
                type="text"
                autoComplete="region"
                variant="outlined"
                value={shippingFormik.values.region}
                onChange={(event) => shippingFormik.setFieldValue('region', event.target.value)}
                error={shippingFormik.touched.region && Boolean(shippingFormik.errors?.region)}
                helperText={shippingFormik.touched.region && shippingFormik.errors?.region}
              >
                {shippingConfiguration?.shipping_one_region ? (
                  <MenuItem value={shippingConfiguration.shipping_regions[0]}>
                    {shippingConfiguration.shipping_regions[0]}
                  </MenuItem>
                ) : shippingConfiguration?.shipping_regions ? (
                  shippingConfiguration?.shipping_regions.map((region) => (
                    <MenuItem key={region} value={region}>
                      {region}
                    </MenuItem>
                  ))
                ) : (
                  REGIONS.map((region) => (
                    <MenuItem key={region[0]} value={region[1]}>
                      {region[1]}
                    </MenuItem>
                  ))
                )}
              </TextField>
            </Grid>
            <Grid item xs={6} className={styles.inputContainer}>
              <TextField
                fullWidth
                required
                select
                id="commune"
                type="text"
                label="Comuna"
                autoComplete="commune"
                variant="outlined"
                value={shippingFormik.values.commune}
                onChange={(event) => shippingFormik.setFieldValue('commune', event.target.value)}
                error={shippingFormik.touched.commune && Boolean(shippingFormik.errors?.commune)}
                helperText={shippingFormik.touched.commune && shippingFormik.errors?.commune}
              >
                {shippingFormik.values.region ? (
                  shippingConfiguration?.shipping_communes_by_region ? (
                    (
                      shippingConfiguration?.shipping_communes_by_region[
                        shippingFormik.values.region
                      ] || COMMUNES_BY_REGION[shippingFormik.values.region]
                    )
                      ?.sort()
                      .map((commune) => (
                        <MenuItem key={commune} value={commune}>
                          {commune}
                        </MenuItem>
                      ))
                  ) : (
                    COMMUNES_BY_REGION[shippingFormik.values.region]?.sort().map((commune) => (
                      <MenuItem key={commune} value={commune}>
                        {commune}
                      </MenuItem>
                    ))
                  )
                ) : (
                  <MenuItem>Selecciona región</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="address1"
                type="text"
                label="Dirección"
                autoComplete="address1"
                variant="outlined"
                value={shippingFormik.values.address1}
                onChange={shippingFormik.handleChange}
                error={shippingFormik.touched.address1 && Boolean(shippingFormik.errors?.address1)}
                helperText={shippingFormik.touched.address1 && shippingFormik.errors?.address1}
              />
            </Grid>
          </Fragment>
        )}
        {selectedSubscription?.emit_document && invoiceConfiguration && (
          <Fragment>
            {invoiceConfiguration.document === 'selectable' && (
              <Grid item xs={12} className={styles.inputContainer}>
                <Typography className={styles.fieldHeader}>
                  Documento <span className={styles.mandatoryText}>*</span>
                </Typography>
                <TextField
                  className={styles.inputField}
                  id="invoiceDocument"
                  fullWidth
                  select
                  variant="outlined"
                  name="invoiceDocument"
                  defaultValue="receipt"
                  value={invoiceDocument}
                  onChange={(event) => setInvoiceDocument(event.target.value)}
                >
                  <MenuItem value="receipt">Boleta</MenuItem>
                  <MenuItem value="invoice">Factura</MenuItem>
                </TextField>
              </Grid>
            )}
            {invoiceDocument === 'invoice' ? (
              <Fragment>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography variant="body1">Datos factura:</Typography>
                  <Typography>
                    Rut <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="rut"
                    type="text"
                    variant="outlined"
                    value={invoiceInformationFormik.values.rut}
                    onChange={(e) =>
                      invoiceInformationFormik.setFieldValue('rut', formatRUT(e.target.value))
                    }
                    InputLabelProps={{ shrink: !!invoiceInformationFormik.values.rut }}
                    error={Boolean(invoiceInformationFormik.errors?.rut)}
                    helperText={invoiceInformationFormik.errors?.rut}
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Razón Social <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="business_name"
                    type="text"
                    autoComplete="business_name"
                    variant="outlined"
                    value={invoiceInformationFormik.values.business_name}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.business_name &&
                      Boolean(invoiceInformationFormik.errors?.business_name)
                    }
                    helperText={
                      invoiceInformationFormik.touched.business_name &&
                      invoiceInformationFormik.errors?.business_name
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Giro <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="activity"
                    type="text"
                    autoComplete="activity"
                    variant="outlined"
                    value={invoiceInformationFormik.values.activity}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.activity &&
                      Boolean(invoiceInformationFormik.errors?.activity)
                    }
                    helperText={
                      invoiceInformationFormik.touched.activity &&
                      invoiceInformationFormik.errors?.activity
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Región <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    select
                    id="region"
                    type="text"
                    autoComplete="region"
                    variant="outlined"
                    value={invoiceInformationFormik.values.region}
                    onChange={(event) =>
                      invoiceInformationFormik.setFieldValue('region', event.target.value)
                    }
                    error={
                      invoiceInformationFormik.touched.region &&
                      Boolean(invoiceInformationFormik.errors?.region)
                    }
                    helperText={
                      invoiceInformationFormik.touched.region &&
                      invoiceInformationFormik.errors?.region
                    }
                  >
                    {REGIONS.map((region) => (
                      <MenuItem key={region[0]} value={region[1]}>
                        {region[1]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Comuna <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    select
                    id="commune"
                    type="text"
                    autoComplete="commune"
                    variant="outlined"
                    value={invoiceInformationFormik.values.commune}
                    onChange={(event) =>
                      invoiceInformationFormik.setFieldValue('commune', event.target.value)
                    }
                    error={
                      invoiceInformationFormik.touched.commune &&
                      Boolean(invoiceInformationFormik.errors?.commune)
                    }
                    helperText={
                      invoiceInformationFormik.touched.commune &&
                      invoiceInformationFormik.errors?.commune
                    }
                  >
                    {invoiceInformationFormik.values.region ? (
                      COMMUNES_BY_REGION[invoiceInformationFormik.values.region]
                        ?.sort()
                        .map((commune) => (
                          <MenuItem key={commune} value={commune}>
                            {commune}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem>Selecciona región</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Dirección <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="address"
                    type="text"
                    autoComplete="address"
                    variant="outlined"
                    value={invoiceInformationFormik.values.address}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.address &&
                      Boolean(invoiceInformationFormik.errors?.address)
                    }
                    helperText={
                      invoiceInformationFormik.touched.address &&
                      invoiceInformationFormik.errors?.address
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>Email contacto</Typography>
                  <TextField
                    fullWidth
                    id="email"
                    type="text"
                    autoComplete="email"
                    variant="outlined"
                    value={invoiceInformationFormik.values.email}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.email &&
                      Boolean(invoiceInformationFormik.errors?.email)
                    }
                    helperText={
                      invoiceInformationFormik.touched.email &&
                      invoiceInformationFormik.errors?.email
                    }
                  />
                </Grid>
              </Fragment>
            ) : invoiceConfiguration?.ask_rut ? (
              <Fragment>
                <Grid item xs={12} className={styles.inputContainer} mt={1}>
                  <Typography variant="body1">Datos boleta:</Typography>
                  <Typography>
                    Rut <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required={true}
                    id="rut"
                    type="text"
                    autoComplete="extra_fields[id]"
                    variant="outlined"
                    value={invoiceInformationFormik.values.rut}
                    onChange={(e) =>
                      invoiceInformationFormik.setFieldValue('rut', formatRUT(e.target.value))
                    }
                    InputLabelProps={{ shrink: !!invoiceInformationFormik.values.rut }}
                    error={Boolean(invoiceInformationFormik.errors?.rut)}
                    helperText={invoiceInformationFormik.errors?.rut}
                  />
                </Grid>
              </Fragment>
            ) : null}
          </Fragment>
        )}
      </Grid>
    </form>
  );
};
