import React, { ReactNode, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Store
import { RootState } from '../../app/store';
import { SessionState } from '../../features/session/sessionSlice';
import { useSnackbar } from 'notistack';
import { ClientState } from '../../features/client/clientSlice';
import { SellerState } from '../../features/seller/sellerSlice';

export const NoMatch = (): React.ReactElement => {
  const location = {};
  return <Redirect to={{ pathname: '/', state: { from: location } }} />;
};

interface PrivateRouteProps {
  children: ReactNode;
  path: string;
  exact?: boolean;
  permissions?: string[][];
  location?: any;
}

const modules = [
  'home',
  'products',
  'subscriptions',
  'single_payments',
  'payment_plans',
  'buyers',
  'payments',
];

export const PrivateRoute = ({ children, ...rest }: PrivateRouteProps): React.ReactElement => {
  const { logged } = useSelector((state: RootState): SessionState => state.session);
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const hasAccess = logged;

  useEffect(() => {
    if (!logged) {
      history.push('');
    }
  }, [logged, history]);

  const mod = rest.location?.pathname?.split?.('/')?.[2];
  if (!logged) {
    return <Redirect to="/auth/login" />;
  } else if (
    mod &&
    company &&
    modules.includes(mod) &&
    company.active_modules &&
    !company.active_modules.includes(mod)
  ) {
    enqueueSnackbar('No tienes acceso a la sección que estás intentado acceder', {
      variant: 'error',
    });
    return <Redirect to={`/seller/${company?.active_modules?.[0] || 'home'}`} />;
  } else if (!hasAccess) {
    enqueueSnackbar('No tienes acceso a la sección que estás intentado acceder', {
      variant: 'error',
    });
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={() => children} />;
};

interface PublicRouteProps {
  children: ReactNode;
  path: string;
  exact?: boolean;
}
export const PublicRoute = ({ children, ...rest }: PublicRouteProps): React.ReactElement => {
  const { logged } = useSelector((state: RootState): SessionState => state.session);

  if (logged) {
    return <Redirect to="/seller" />;
  }

  return <Route {...rest} render={() => children} />;
};

interface BackofficeRouteProps {
  children: ReactNode;
  path: string;
}

export const BackofficeRoute = ({
  children,
  ...rest
}: BackofficeRouteProps): React.ReactElement => {
  const { backofficeLogged } = useSelector((state: RootState): SessionState => state.session);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const hasAccess = backofficeLogged;

  useEffect(() => {
    if (!backofficeLogged) {
      history.push('');
    }
  }, [backofficeLogged, history]);

  if (!backofficeLogged) {
    return <Redirect to="/backoffice/login" />;
  } else if (!hasAccess) {
    enqueueSnackbar('No tienes acceso a la sección que estás intentado acceder', {
      variant: 'error',
    });
    return <Redirect to="/backoffice/login" />;
  }

  return <Route {...rest} render={() => children} />;
};

interface PublicBackofficeRouteProps {
  children: ReactNode;
  path: string;
}

export const PublicBackofficeRoute = ({
  children,
  ...rest
}: PublicBackofficeRouteProps): React.ReactElement => {
  const { backofficeLogged } = useSelector((state: RootState): SessionState => state.session);

  if (backofficeLogged) {
    return <Redirect to="/backoffice/dashboard" />;
  }

  return <Route {...rest} render={() => children} />;
};

interface ClientRouteProps {
  children: ReactNode;
  path: string;
  exact?: boolean;
  permissions?: string[][];
}

export const ClientRoute = ({ children, ...rest }: ClientRouteProps): React.ReactElement => {
  const { clientLogged } = useSelector((state: RootState): SessionState => state.session);
  const { company_id } = useSelector(({ client }: { client: ClientState }) => client);
  const history = useHistory();

  useEffect(() => {
    if (!clientLogged && !history.location.pathname.includes('error')) {
      history.replace(
        `/client/login${company_id && `?company_id=${company_id}`}&token_expired=true`
      );
    }
  }, [clientLogged, history]);

  if (!clientLogged && !history.location.pathname.includes('error')) {
    return (
      <Redirect
        to={`/client/login${company_id && `?company_id=${company_id}`}&token_expired=true`}
      />
    );
  }

  return <Route {...rest} render={() => children} />;
};

export default {
  NoMatch,
  PrivateRoute,
  PublicRoute,
  BackofficeRoute,
  PublicBackofficeRoute,
  ClientRoute,
};
