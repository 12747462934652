import React, { useState } from 'react';
import { Typography, Paper, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import { backofficeApi } from '../../common/api';
import { GenericObject } from '../../app/type';

const Actions = (): React.ReactElement => {
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();

  function handleCCTemplate() {
    backofficeApi.companies.downloadCCTemplate().then((data) => {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  function uploadCCTemplate() {
    console.log('Subir template');
    if (file) {
      backofficeApi.companies.sendCCFile(file).then((data) => {
        alert(objectToString(data.message));
      });
    } else {
      enqueueSnackbar('No se ha seleccionado un archivo', {
        variant: 'error',
      });
    }
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }

  function objectToString(obj: GenericObject): string {
    return Object.keys(obj)
      .map((key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          return `Fila ${key}: ${objectToString(value)}`;
        }
        return `${key}: ${value}`;
      })
      .join('\n');
  }

  return (
    <Paper style={{ padding: '2rem' }} elevation={2}>
      <div style={{ marginBottom: '2rem', marginTop: '1rem' }}>
        <Typography variant="h5">Proyectos</Typography>
        <Typography variant="body1">
          Importa los Códigos de comercio, datos bancarios y datos de facturación para varios
          proyectos (CollectionCompany)
        </Typography>
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Button onClick={handleCCTemplate} variant="contained">
          <Typography variant="body1">Descargar plantilla</Typography>
        </Button>
        <Button onClick={uploadCCTemplate} variant="outlined">
          <Typography variant="body1">Subir Archivo</Typography>
        </Button>
        <input
          type="file"
          onChange={handleFileChange}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
    </Paper>
  );
};

export default Actions;
